<template>
  <div>
    <div class="content-inventory mt-4 mt-sm-5">
      <div class="row gx-lg-5">
        <div class="col-lg-4 border-inventory">
          <ul class="nav nav-pills mb-3" id="inventory-tab" role="tablist">
            <li class="nav-item mb-3" role="presentation">
              <button class="nav-link active" id="material-tab" data-bs-toggle="pill" data-bs-target="#material"
                type="button" role="tab" aria-controls="material" aria-selected="true">
                <div class="d-flex align-items-center">
                  <div class="img-inventory">
                    <img src="img/material.png" class="img-fluid">
                  </div>
                  <div class="col ms-3">
                    <div class="text-sm text-medium color-gray-100 ">Bahan Baku</div>
                    <div class="text-sm color-gray-60 ">{{ rawMaterialTotal | numFormat }} Tersedia</div>
                  </div>
                </div>
              </button>
            </li>
            <li class="nav-item mb-3" role="presentation">
              <button class="nav-link" id="layanan-tab" data-bs-toggle="pill" data-bs-target="#layanan" type="button"
                role="tab" aria-controls="layanan" aria-selected="false">
                <div class="d-flex align-items-center">
                  <div class="img-inventory">
                    <img src="img/layanan.png" class="img-fluid">
                  </div>
                  <div class="col ms-3">
                    <div class="text-sm text-medium color-gray-100 ">Layanan</div>
                    <div class="text-sm color-gray-60 ">{{ treatmentTotal | numFormat }} Tersedia</div>
                  </div>
                </div>
              </button>
            </li>
            <li class="nav-item mb-3" role="presentation">
              <button class="nav-link" id="produk-tab" data-bs-toggle="pill" data-bs-target="#produk" type="button"
                role="tab" aria-controls="produk" aria-selected="false">
                <div class="d-flex align-items-center">
                  <div class="img-inventory">
                    <img src="img/produk.png" class="img-fluid">
                  </div>
                  <div class="col ms-3">
                    <div class="text-sm text-medium color-gray-100 ">Produk</div>
                    <div class="text-sm color-gray-60 ">{{ productTotal | numFormat }} Tersedia</div>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="material" role="tabpanel" aria-labelledby="material-tab"
              tabindex="0">
              <div class="text-semiBold mb-3">Bahan Baku</div>
              <div class="position-relative mb-3">
                <div class="form-search">
                  <input type="text" class="form-control" name="" id="" placeholder="Cari Bahan Baku" v-debounce:300.lock="searchRawMaterial" v-model="rawMaterialSearch">
                </div>
              </div>

              <div class="row gx-3">
                <div class="col-sm-6" v-for="rawMaterial in rawMaterials" :key="rawMaterial.id">
                  <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3 mb-3">
                    <div class="text-sm text-medium mb-2">{{ rawMaterial.productName }}</div>
                    <ul class="item-info m-0">
                      <li v-if="rawMaterial.stock <= 10">
                        <span class="text-xs color-red-100 text-medium" v-if="rawMaterial.stock <= 0">Habis</span>
                        <span class="text-xs color-orange text-medium" v-else-if="rawMaterial.stock <= 10">Hampir Habis</span>
                      </li>
                      <li>
                        <span class="text-xs color-gray-60 text-medium">Tersisa {{ rawMaterial.stock }} produk</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p class="text-center text-sm" v-if="rawMaterials.length == 0">Tidak ada bahan baku</p>
              <p class="text-center text-sm" v-if="rawMaterials.length > 0 && rawMaterialCurrentPage < rawMaterialTotalPage">
                <a class="color-gray-100" @click="getRawMaterial( rawMaterialCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
            <!--End Ingeredients-->

            <div class="tab-pane fade" id="layanan" role="tabpanel" aria-labelledby="layanan-tab" tabindex="0">
              <div class="text-semiBold mb-3">Layanan</div>
              <div class="position-relative mb-3">
                <div class="form-search">
                  <input type="text" class="form-control" name="" id="" placeholder="Cari Layanan" v-debounce:300.lock="searchTreatment" v-model="treatmentSearch">
                </div>
              </div>

              <div class="row gx-3">
                <div class="col-sm-6" v-for="treatment in treatments" :key="treatment.id">
                  <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3 mb-3">
                    <div class="text-sm text-medium mb-2">{{ treatment.name }}</div>
                    <p class="text-xs color-gray-60 text-medium mb-0" v-if="treatment.typeName == 'single' && treatment.collectionTreatment.description">
                      {{ treatment.collectionTreatment.description.length > 50 ? treatment.collectionTreatment.description.substring(0,50) : treatment.collectionTreatment.description }}
                      <a class="text-medium detail-service" @click="detailTreatment = treatment" v-if="treatment.collectionTreatment.description.length > 50">Lihat Detail</a>
                    </p>
                    <p class="text-xs color-gray-60 text-medium mb-0" v-if="treatment.typeName == 'bundle' && treatment.collectionTreatment.length > 0">
                      {{ treatment.collectionTreatment.map(x => x.description).join(', ').length > 50 ? treatment.collectionTreatment.map(x => x.description).join(', ').substring(0,50) : treatment.collectionTreatment.map(x => x.description).join(', ') }}
                      <a class="text-medium detail-service" @click="detailTreatment = treatment" v-if="treatment.collectionTreatment.map(x => x.description).join(', ').length > 50">Lihat Detail</a>
                    </p>
                    <ul class="item-info m-0">
                      <li>
                        <span class="text-xs color-gray-60 text-medium">{{ treatment.durationHours | numFormat }} Jam {{ treatment.durationMinutes | numFormat }} Mnt</span>
                      </li>
                      <li>
                        <span class="text-xs color-red-100 text-medium">{{ treatment.price | currency }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <p class="text-center text-sm" v-if="treatments.length == 0">Tidak ada layanan</p>
              <p class="text-center text-sm" v-if="treatments.length > 0 && treatmentCurrentPage < treatmentTotalPage">
                <a class="color-gray-100" @click="getTreatment( treatmentCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
            <!--End Layanan-->

            <div class="tab-pane fade" id="produk" role="tabpanel" aria-labelledby="produk-tab" tabindex="0">
              <div class="text-semiBold mb-3">Produk</div>
              <div class="position-relative mb-3">
                <div class="form-search">
                  <input type="text" class="form-control" name="" id="" placeholder="Cari Produk" v-debounce:300.lock="searchProduct" v-model="productSearch">
                </div>
              </div>

              <div class="d-grid grid-inventory gap-3">
                <div class="bg-white border-1 border-solid border-color-gray rounded-12 p-3" v-for="product in products" :key="product.id">
                  <div class="img-product center-content mb-3">
                    <img :src="product.product && product.product.detailItemImage.length > 0 ? product.product.detailItemImage[0] : '/img/product-1.png'" class="img-fluid">
                  </div>
                  <div class="text-xs text-medium text-center px-2 mb-2">{{ product.productName }}</div>
                  <div class="text-xs color-gray-60 text-center mb-2">Stok {{ product.stock | numFormat }}</div>
                  <div class="text-xs color-red-100 text-semiBold text-center mb-2">{{ product.price | currency }}</div>
                </div>
                <!--End List Product-->
              </div>
              <p class="text-center text-sm" v-if="products.length == 0">Tidak ada produk</p>
              <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
                <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
            <!--End Produk-->
          </div>
        </div>
      </div>
    </div>

    <div class="overlay-popup">
      <div id="detail-service" class="box-xs hidden bg-white rounded-12 p-3" v-if="detailTreatment">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold text-xs">Detail Layanan</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <p class="text-xs color-gray-60" v-if="detailTreatment.typeName == 'single'">{{ detailTreatment.collectionTreatment.description }}</p>
        <p class="text-xs color-gray-60" v-if="detailTreatment.typeName == 'bundle'">{{ detailTreatment.collectionTreatment.map(x => x.description).join(', ') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rawMaterials: [],
      rawMaterialCurrentPage: 1,
      rawMaterialTotalPage: 1,
      rawMaterialLimit: 10,
      rawMaterialSortby: 'id.desc',
      rawMaterialSearch: '',
      rawMaterialTotal: 0,

      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      treatmentSearch: '',
      treatmentTotal: 0,

      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',
      productTotal: 0,

      user: JSON.parse(localStorage.getItem('user')),
      detailTreatment: null
    }
  },
  methods: {
    getTreatment( page ) {
      let _ = this
      
      _.axios.get('/inventory/treatment'
          + '?page=' + page
          + '&limit=' + _.treatmentLimit
          + '&sortBy=' + _.treatmentSortby
          + '&kfsId=' + _.user.kfsId
          + (_.treatmentSearch ? '&search=' + _.treatmentSearch : '')
        )
        .then( resp => {
          const data = resp.data.data
          _.treatmentTotal = data.count
          _.treatmentCurrentPage = data.currentPage
          _.treatmentTotalPage = data.totalPages

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.treatments.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getProduct( page ) {
      let _ = this
      
      _.axios.get('/inventory/product'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + (_.productSearch ? '&search=' + _.productSearch : '')
        )
        .then( resp => {
          const data = resp.data.data
          _.productTotal = data.count
          _.productCurrentPage = data.currentPage
          _.productTotalPage = data.totalPages

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.products.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getRawMaterial( page ) {
      let _ = this
      
      _.axios.get('/inventory/raw-material'
          + '?page=' + page
          + '&limit=' + _.rawMaterialLimit
          + '&sortBy=' + _.rawMaterialSortby
          + (_.rawMaterialSearch ? '&search=' + _.rawMaterialSearch : '')
        )
        .then( resp => {
          const data = resp.data.data
          _.rawMaterialTotal = data.count
          _.rawMaterialCurrentPage = data.currentPage
          _.rawMaterialTotalPage = data.totalPages

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.rawMaterials.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchTreatment() {
      this.treatments = []
      this.getTreatment(1)
    },
    searchProduct() {
      this.products = []
      this.getProduct(1)
    },
    searchRawMaterial() {
      this.rawMaterials = []
      this.getRawMaterial(1)
    },
  },
  mounted() {
    let _ = this
    _.getTreatment(1)
    _.getProduct(1)
    _.getRawMaterial(1)
  },
}
</script>