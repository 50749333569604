<template>
  <div>
    <div class="bg-white shadow rounded-12 p-3">
      <div class="row gx-2 justify-content-between mb-3">
        <div class="col-lg-12 col-12 col-sm-12">
          <div class="position-relative">
            <div class="form-search">
              <input type="text" class="form-control" name="" id="" placeholder="Cari kata kunci" v-model="search">
            </div>
          </div>
        </div>
      </div>
      <Table :table="table"/>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'
export default {
	components: {
    Table
	},
  data() {
    return {
      table: {
        options: {
          columns: [
            { name: 'Invoice', sortable: true, reference: 'invoiceCode' },
            { name: 'Nama', sortable: true, reference: 'fullName' },
            { name: 'Status', sortable: true, reference: 'status' },
            { name: 'Invoice Date', sortable: true, reference: 'invoiceDate' },
            { name: 'Due Date', sortable: true, reference: 'dueDate' },
            { name: 'Location', sortable: true, reference: 'since' },
            { name: 'Total Tip', sortable: true, reference: 'totalTips' },
            { name: 'Gross Total', sortable: true, reference: 'grossTotal' },
          ],
          rows: [
            { reference: 'invoiceCode', type: 'text' },
            { reference: 'fullName', type: 'text' },
            { reference: 'status', type: 'text' },
            { reference: 'invoiceDate', type: 'date' },
            { reference: 'dueDate', type: 'date' },
            { reference: 'since', type: 'text' },
            { reference: 'totalTips', type: 'currency' },
            { reference: 'grossTotal', type: 'currency' },
          ],
        },
        data: []
      },
      page: 1,
      limit: 10,
      sortby: 'id.desc',
      search: '',
      selected: [],
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    get() {
      let _ = this
      _.axios.get('/old-transaction'
        + '?page=' + _.page
        + '&limit=' + _.limit
        + '&sortBy=' + _.sortby
        + '&search=' + _.search
        + '&kfsId=' + _.user.kfsId
        + '&customerId=' + _.$route.params.id
      )
      .then( resp => {
        _.table.data = resp.data.data
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    }
  },
  mounted() {
    let _ = this
    _.get()

    _.$root.$on("changePage", (data) => {
      _.page = data
    });
    _.$root.$on("changeLimit", (data) => {
      _.limit = data
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.sortby = 'id.desc'
      } else {
        _.sortby = data
      }
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  },
  watch: {
    page: function(){
      this.get()
    },
    limit: function(){
      this.get()
    },
    sortby: function(){
      this.get()
    },
    search: function() {
      this.get()
    }
  }
}
</script>