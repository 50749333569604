<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span> Tambah Pelanggan Baru
    </a>

    <div class="setting-content mt-4 col-xl-10">
      <ValidationObserver ref="observer"> 
        <form @submit.prevent="store()">
          <div class="d-flex align-items-center mb-4">
            <div class="img-profile">
              <img :src="model.photo ? model.photo : '/img/user-default.jpg'" class="img-fluid">
            </div>
            <div class="col-8 ms-lg-4 ms-3">
              <ValidationProvider name="Foto" v-slot="{ errors }" rules="" ref="photo">
                <div class="upload-btn">
                  <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
                  <label class="btn btn-primary py-1" for="upload">
                    <img src="/img/icons/icn-cam.svg" class="img-fluid">
                    <span class="text-xs color-white ms-2 text-normal">Pilih Foto</span>
                  </label>
                </div>
                <div class="text-xs color-gray-40 col-12 col-sm-auto mt-1 ">Ukuran file: max {{ maxFileSize }} Mb. ({{ '.' + allowedExtension.join(' .').toUpperCase() }})</div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>

          <div class="row gx-4 mb-3 mb-sm-4">
            <div class="col-sm-6">
              <ValidationProvider name="Nama" v-slot="{ errors }" rules="required" ref="name">
                <div class="mb-3">
                  <label class="text-sm color-gray-60 mb-2">Nama </label>
                  <div class="position-relative">
                    <input type="text" class="form-control" name="" placeholder="" v-model="model.name">
                  </div>
                  <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="No. Handphone" v-slot="{ errors }" rules="required|min:8|max:15" ref="phone">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">No. Handphone </label>
                <div class="position-relative">
                  <input type="text" class="form-control" name="" placeholder="" v-model="model.phone" @keypress="numbersOnly($event)">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
              <div class="mb-3">
                <label class="text-sm color-gray-60 mb-2">Email</label>
                <div class="position-relative">
                  <input type="email" class="form-control" name="" placeholder="" v-model="model.email">
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="Tanggal lahir" v-slot="{ errors }" rules="required" ref="bod">
              <div class="mb-3">
                <label class="text-sm color-gray-40 mb-2">Tanggal Lahir</label>
                <div class="position-relative">
                  <div class=" icn-calendar">
                    <date-picker v-model="model.bod" :config="datePickerOptions" placeholder="Pilih Tanggal"></date-picker>
                  </div>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="Jenis kelamin" v-slot="{ errors }" rules="required" ref="gender">
              <div class="mb-3">
                <label class="color-gray-60 text-sm text-sm mb-2">Jenis Kelamin</label>
                <div class="position-relative">
                  <div class="icn-chevron">
                    <select class="form-select" v-model="model.gender">
                      <option :value="null">Pilih Jenis Kelamin</option>
                      <option value="laki">Laki-laki</option>
                      <option value="perempuan">Perempuan</option>
                    </select>
                  </div>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="Tags" v-slot="{ errors }" rules="required" ref="since">
              <div class="mb-3">
                <label class="color-gray-60 text-sm text-sm mb-2">Tags</label>
                <multiselect 
                  label="name"
                  track-by="id" 
                  placeholder="Pilih Tags"
                  v-model="selectedRegency"
                  @search-change="getRegency"
                  :options="regencies"
                  :multiple="false" 
                  :clear-on-select="false" 
                  :close-on-select="true"
                  :internal-search="false"
                  disabled>
                  <span slot="noResult">Maaf, Tags tidak ditemukan</span>
                </multiselect>
                <input type="hidden" name="" v-model="model.since">
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required" ref="address">
              <div class="mb-3">
                <label class="color-gray-60 text-sm text-sm mb-2">Alamat</label>
                <div class="position-relative">
                  <textarea class="form-control" placeholder="" name="" rows="3" v-model="model.address"></textarea>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider name="Catatan" v-slot="{ errors }" rules="" ref="note">
              <div class="mb-3">
                <label class="color-gray-60 text-sm text-sm mb-2">Catatan</label>
                <div class="position-relative">
                  <textarea class="form-control" placeholder="" name="" rows="3" v-model="model.note"></textarea>
                </div>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6 mt-2">
              <div class="mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-sm text-semiBold">Kirim notifikasi marketing:</div>

                  <label class="toggled">
                    <input class="toggle-checkbox" type="checkbox" v-model="model.enablePushEmail">
                    <div class="toggle-switch"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="center-content">
            <div class="col-12 col-sm-4">
              <button class="btn btn-primary w-100" type="submit">Simpan</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <!-- <json-viewer
      :value="model"
      :expand-depth="5"
      copyable
      boxed
      sort></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      maxFileSize: 5,
      allowedExtension: ['jpg', 'jpeg', 'png'],
      datePickerOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      model: {
        kfsId: null,
        name: null,
        photo: null,
        gender: null,
        bod: null,
        phone: null,
        email: null,
        address: null,
        enablePushEmail: false,
        since: "",
        note: ""
      },
      regencies: [],
      selectedRegency: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    async handleUpload(event){
      let _ = this
      let file = event.target.files[0]
      _.model.photo = await _.upload( file, _.allowedExtension, _.maxFileSize, 'photo' )
    },
    async store() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.axios.post('/customers', _.model)
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.back()
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              _.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          }
        })
    },
    getRegency( key ) {
      let _ = this
      _.axios.get('/misc/regencies'
        + '?search=' + _.user.kfs.since)
        .then( resp => {
          _.regencies = resp.data.data.rows
          _.selectedRegency = _.regencies[0]
        })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    }
  },
  mounted() {
    this.getRegency()

    let user = JSON.parse(localStorage.getItem('user'))
    this.model.kfsId = user.kfsId
  },
  watch: {
    selectedRegency: function() {
      let _ = this
      _.model.since = this.selectedRegency.name
    }
  }
}
</script>