<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="col">
        <div class="position-relative mb-3">
          <div class="form-search">
            <input type="text" class="form-control" name="" id="" placeholder="Cari nama atau treatment" value=""  v-debounce:300.lock="searchBooking" v-model="bookingSearch">
          </div>
        </div>
      </div>
      <div class="col-auto ms-3">
        <ul class="item-info lg">
          <li><a href="#" class="text-base filter text-medium">Filter</a></li>
          <li><a class="text-base text-medium" @click="exportBooking()">Export</a></li>
        </ul>
      </div>
    </div>

    <ul class="nav nav-tabs bbooking-0 mb-3 border-0" id="tabTop" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="all" data-bs-toggle="tab" data-bs-target="#all-pane" type="button"
          role="tab" aria-controls="all-pane" aria-selected="true"
          @click="bookingStatus = 'all'">Semua {{ bookingCounter.all | numFormat }}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="waiting-tab" data-bs-toggle="tab" data-bs-target="#waiting-tab-pane"
          type="button" role="tab" aria-controls="waiting-tab-pane" aria-selected="false"
          @click="bookingStatus = -2">Menunggu Pembayaran {{ bookingCounter.waitingPayment | numFormat }}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="layanan-tab" data-bs-toggle="tab" data-bs-target="#layanan-tab-pane"
          type="button" role="tab" aria-controls="layanan-tab-pane" aria-selected="false"
          @click="bookingStatus = -1">Menunggu Layanan {{ bookingCounter.waitingTreatment | numFormat }}</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="staff-tab" data-bs-toggle="tab" data-bs-target="#staff-tab-pane" type="button"
          role="tab" aria-controls="staff-tab-pane" aria-selected="false"
          @click="bookingStatus = 0">Menunggu Staff {{ bookingCounter.waitingStaff | numFormat }}</button>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="all-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="0"></div>
      <div class="tab-pane fade" id="waiting-tab-pane" role="tabpanel" aria-labelledby="waiting-tab" tabindex="0"></div>
      <div class="tab-pane fade" id="layanan-tab-pane" role="tabpanel" aria-labelledby="layanan-tab" tabindex="0"></div>
      <div class="tab-pane fade" id="staff-tab-pane" role="tabpanel" aria-labelledby="staff-tab" tabindex="0"></div>
    </div>

    <div class="row gx-3" v-if="bookings.length > 0">
      <CardBo v-for="booking in bookings" :key="booking.id" :data="booking"/>
    </div>
    <p class="text-center text-sm" v-if="bookings.length == 0">Tidak ada booking</p>
    <p class="text-center text-sm" v-if="bookings.length > 0 && bookingCurrentPage < bookingTotalPage">
      <a class="color-gray-100" @click="getBooking( bookingCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>

    <div class="overlay-popup ">
      <OrderFilter/>
    </div>
  </div>
</template>
<script>
import CardBo from './components/CardBo.vue'
import OrderFilter from '@/components/modals/OrderFilter.vue'

export default {
  components: {
    CardBo,
    OrderFilter
  },
  data() {
    return {
      bookings: [],
      bookingCurrentPage: 1,
      bookingTotalPage: 1,
      bookingLimit: 10,
      bookingSortby: 'id.desc',
      bookingSearch: '',
      bookingStatus: 'all',
      bookingCounter: {
        all: 0,
        waitingPayment: 0,
        waitingTreatment: 0,
        waitingStaff: 0
      },
      filter: {
        startDate: null,
        endDate: null,
        treatmentId: null
      },
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    searchBooking() {
      this.bookings = []
      this.getBooking(1)
    },
    getBooking( page ) {
      let _ = this

      let status = _.bookingStatus
      if (_.bookingStatus == 'all') {
        status = 'all'
      }

      let startDate = null
      if ( _.filter.startDate && _.filter.endDate ) {
        startDate = 'betweenDate:' + _.filter.startDate + ',' + _.filter.endDate
      } else if ( _.filter.startDate ) {
        startDate = 'gte:' + _.filter.startDate
      } else if ( _.filter.endDate ) {
        startDate = 'lte:' + _.filter.endDate
      }
      
      _.axios.get('/sales-order/booking-treatment'
          + '?page=' + page
          + '&limit=' + _.bookingLimit
          + '&sortBy=' + _.bookingSortby
          + '&kfsId=' + _.user.kfsId
          + (status != 'all' ? '&status=' + status : '')
          + (_.bookingSearch ? '&search=' + _.bookingSearch : '')
          + (_.filter.treatmentId ? '&treatmentId=' + _.filter.treatmentId : '')
          + (startDate ? '&startDate=' + startDate : '')
        )
        .then( resp => {
          const data = resp.data.data

          _.bookingCounter = data.counterTab

          if ( data.rows.length > 0 ) {
            _.bookingCurrentPage = data.currentPage
            _.bookingTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.bookings.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    exportBooking() {
      let _ = this

      let status = _.bookingStatus
      if (_.bookingStatus == 'all') {
        status = 'all'
      }

      let startDate = null
      if ( _.filter.startDate && _.filter.endDate ) {
        startDate = 'betweenDate:' + _.filter.startDate + ',' + _.filter.endDate
      } else if ( _.filter.startDate ) {
        startDate = 'gte:' + _.filter.startDate
      } else if ( _.filter.endDate ) {
        startDate = 'lte:' + _.filter.endDate
      }
      
      _.axios.get('/sales-order/booking-treatment/export'
          + '?sortBy=' + _.bookingSortby
          + (status != 'all' ? '&status=' + status : '')
          + (_.bookingSearch ? '&search=' + _.bookingSearch : '')
          + (_.filter.treatmentId ? '&treatmentId' + _.filter.treatmentId : '')
          + (startDate ? '&startDate=' + startDate : '')
          + '&kfsId=' + _.user.kfsId
        ,{responseType: 'arraybuffer'})
        .then(resp => {
            let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            let link        = document.createElement('a')
            link.href       = window.URL.createObjectURL(blob)
            link.download   = 'BOOKING-ORDER-' + _.$moment().format('YYYYMMDDHHmmsss') + '.xlsx'
            link.click()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    if ( this.$route.query.bookingStatus ) {
      this.bookingStatus = this.$route.query.bookingStatus
    } else {
      this.getBooking(1)
    }

    this.$root.$on('setFilter', (data) => {
      this.filter = data
      this.bookings = []
      this.getBooking(1)
    })
  },
  watch: {
    bookingStatus: function() {
      this.bookings = []
      this.getBooking(1)
    }
  }
}
</script>