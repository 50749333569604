<template>
  <div>
    <section v-if="state == 'treatment'">
      <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Pilih Treatment</a>

      <div class="row gx-xl-5">
        <div class="col-lg-7 mb-4">
          <div class="position-relative mb-3">
            <div class="form-search">
              <input type="text" class="form-control" name="" id="" placeholder="Cari Treatment" v-debounce:300.lock="searchTreatment" v-model="treatmentSearch">
            </div>
          </div>

          <div v-for="treatment in treatments" :key="treatment.id"
            class="position-relative border-1 border-solid border-color-gray rounded-12 bg-white deco-treatment me-2 me-lg-0 p-3 mb-3">
            <div class="d-flex">
              <div class="me-3">
                <img :src="treatment.collectionImage && treatment.collectionImage.length > 0 ? treatment.collectionImage[0] : '/img/user-1.jpg'" class="img-48">
              </div>
              <div class="col">
                <div class="text-medium mb-1">{{ treatment.name }}</div>
                <ul class="item-info mb-0">
                  <li><span>{{ treatment.durationHours ? treatment.durationHours : 0 }} Jam {{ treatment.durationMinutes ? treatment.durationMinutes : 0 }} Mnt</span></li>
                  <li>
                    <div class="color-red-100 text-semiBold">{{ treatment.price | currency }}</div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="choose-treatment">
              <input type="checkbox" name="treatment" class="hidden" :id="'treatment-' + treatment.id" 
                :checked="selectedTreatments.map( x => x.id ).indexOf(treatment.id) != -1"
                @change="selectUnselectTreatment($event, treatment)">
              <label :for="'treatment-' + treatment.id" class="cursor-pointer"></label>
            </div>
          </div>
          <p class="text-center text-sm" v-if="treatments.length == 0">Tidak ada treatment</p>
          <p class="text-center text-sm" v-if="treatments.length > 0 && treatmentCurrentPage < treatmentTotalPage">
            <a class="color-gray-100" @click="getTreatment( treatmentCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
          </p>
        </div>
        <!--End List Treatment-->

        <div class="col-lg-5">
          <div class="accordion mb-3">
            <div class="accordion-item  border-1 border-solid border-color-gray">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button text-sm color-gray-100" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  {{ selectedTreatments.length }} Terpilih
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne">
                <div class="d-flex justify-content-between align-items-center p-4"
                  v-for="treatment in selectedTreatments" :key="treatment.id">
                  <div class="text-sm">{{ treatment.name }}</div>
                  <div class="col-auto">
                    <div class="d-flex">
                      <div class="text-xs color-red-100 text-semiBold">{{ treatment.price | currency }}</div>
                      <button class="btn p-0 ms-2" type="button" @click="unselectTreatment(treatment)">
                        <span class="icon-ico-minus-circle color-gray-60"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button @click="state = 'member'" class="btn btn-primary w-100" :disabled="selectedTreatments.length == 0">Selanjutnya</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-else-if="state == 'member'">
      <a @click="state = 'treatment'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Booking</a>
      
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <form @submit.prevent="createBookingTreatment()">
          <div class="row gx-xl-5">
            <div class="col-lg-7 mb-4">
              <div class="row gx-3">
                <div class="col-sm-6 mb-3">
                  <div class="choose-user">
                    <input type="radio" class="hidden" id="member" name="member" v-model="memberType" :value="'member'">
                    <label for="member" class="cursor-pointer"><span class="ms-4">Member</span> </label>
                  </div>
                </div>
                <div class="col-sm-6 mb-3">
                  <div class="choose-user">
                    <input type="radio" class="hidden" id="guest" name="member" v-model="memberType" :value="'new'">
                    <label for="guest" class="cursor-pointer"><span class="ms-4">Kustomer Baru</span> </label>
                  </div>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Nama" v-slot="{ errors }" rules="required" ref="name">
                    <label class="text-xs color-gray-40 mb-2">Nama </label>
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.name">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Nomor handphone" v-slot="{ errors }" rules="required|min:8|max:15" ref="phoneNumber">
                    <label class="text-xs color-gray-40 mb-2">Nomor Handphone </label>
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.phoneNumber" @keypress="numbersOnly($event)">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
                    <label class="text-xs color-gray-40 mb-2">Email </label>
                    <div class="position-relative">
                      <input type="email" class="form-control" name="" placeholder="" v-model="model.customer.email">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required" ref="address">
                    <label class="text-xs color-gray-40 mb-2">Alamat </label>
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.address">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Jenis kelamin" v-slot="{ errors }" rules="required" ref="gender">
                    <label class="text-xs color-gray-40 mb-2">Jenis Kelamin </label>
                    <div class="position-relative">
                      <div class="icn-chevron">
                        <select class="form-select" v-model="model.customer.gender">
                          <option :value="null">Pilih Jenis Kelamin</option>
                          <option value="laki">Laki-laki</option>
                          <option value="perempuan">Perempuan</option>
                        </select>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Tanggal lahir" v-slot="{ errors }" rules="required" ref="dob">
                    <label class="text-xs color-gray-40 mb-2">Tanggal Lahir</label>
                    <div class="position-relative">
                      <div class=" icn-calendar">
                        <date-picker v-model="model.customer.dob" :config="datePickerDobOptions" placeholder="Pilih Tanggal"></date-picker>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-xl-12 mb-3" v-else>
                  <ValidationProvider name="Nama kustomer" v-slot="{ errors }" rules="required" ref="name">
                    <label class="text-xs color-gray-40 mb-2">Nama Kustomer</label>
                    <multiselect 
                        track-by="id" 
                        placeholder="Pilih Kustomer"
                        v-model="selectedCustomer"
                        @search-change="getCustomer"
                        :options="customers"
                        :custom-label="customLabel"
                        :multiple="false" 
                        :clear-on-select="false" 
                        :close-on-select="true"
                        :internal-search="false">
                        <span slot="noResult">Maaf, Customer tidak ditemukan</span>
                    </multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="text-sm text-semiBold mb-3">Waktu Booking</div>
              <div class="row gx-3">
                <div class="col-sm-6 mb-3">
                  <ValidationProvider name="Tanggal" v-slot="{ errors }" rules="required" ref="startDate">
                    <label class="text-xs color-gray-40 mb-2">Tanggal</label>
                    <div class="position-relative">
                      <div class=" icn-calendar">
                        <date-picker v-model="model.bookingDate" :config="datePickerOptions" placeholder="Pilih Tanggal"></date-picker>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="showTimePicker">
                  <ValidationProvider name="Jam mulai" v-slot="{ errors }" rules="required" ref="startDate">
                    <label class="text-xs color-gray-40 mb-2">Jam Mulai</label>
                    <div class="position-relative">
                      <div class=" icn-clock">
                        <date-picker v-model="model.bookingTime" :config="timePickerOptions" placeholder="Pilih Jam"></date-picker>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 mb-3">
                  <ValidationProvider name="Staff" v-slot="{ errors }" rules="" ref="staffAccountId">
                    <label class="text-xs color-gray-40 mb-2">Staff</label>
                    <div class="position-relative">
                      <div class="">
                        <input type="text" class="form-control" name="" id="choose-staff" placeholder="Pilih staff" v-model="selectedStaff.profile.fullName">
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 mb-3">
                  <label class="text-xs color-gray-40 mb-2">Keluhan</label>
                  <div class="position-relative">
                    <div class="">
                      <input type="text" class="form-control" name="" placeholder="Tulis keluhan pasien" v-model="model.description">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--End Form-->

            <div class="col-lg-5">
              <div class="text-sm text-semiBold mb-2">Rincian Booking</div>
              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <div class="d-flex justify-content-between mb-3">
                  <div class="col-auto">
                    <div class="text-xs color-gray-40">Treatment</div>
                  </div>
                  <div class="col-8">
                    <div class="text-xs text-medium text-right d-block">{{ selectedTreatments.map( x => x.name ).join(", ") }}</div>
                  </div>

                </div>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="text-xs color-gray-40">Harga</div>
                  <div class="text-xs text-medium">{{ totalPrice | currency }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="text-xs color-gray-40">Durasi</div>
                  <div class="text-xs text-medium">{{ convertMinute(totalDuration) }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center ">
                  <div class="text-xs color-gray-40">Waktu Booking</div>
                  <div class="text-xs text-medium" v-if="model.bookingDate && model.bookingTime">{{ (model.bookingDate + ' ' + model.bookingTime) | moment('DD MMM YYYY, HH:mm')}} WIB</div>
                </div>
              </div>

              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-xs color-gray-40">Down Payment</div>
                  <div class="text-xs text-semiBold color-red-100">{{ totalPrice * 0.3 | currency }}</div>
                </div>
              </div>

              <button class="btn btn-primary w-100 btn-pay" type="button" v-if="!invalid">Pilih Metode Pembayaran</button>
              <button class="btn btn-primary w-100" type="submit" v-else>Pilih Metode Pembayaran</button>
            </div>
            <!--End Right Side Bar-->
          </div>
        </form>
      </ValidationObserver>
    </section>
    <section v-else-if="state == 'finish' && paymentDetail">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="bg-white rounded-20 bg-pay p-4">
            <div class="row py-3 py-sm-5">
              <div class="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2">
                <div class="text-center">
                  <div class="text-sm text-semiBold mb-2">Booking Berhasil Dibuat</div>
                  <p class="text-sm color-gray-40">Segera lakukan pembayaran sebelum</p>
                  <div class="text-sm color-red-100 text-semiBold">{{ booking.paymentDetail.expired_payment_time | moment('DD MMM YYYY, HH:mm')}} WIB</div>
                  <hr class="dashed">
                  <p class="text-sm color-gray-40 mb-2">Metode Pembayaran</p>
                  <div class="center-content mb-3">
                    <div class="me-2"><img :src="paymentDetail.icon" class="img-fluid" style="max-width: 45px;"></div>
                    <div class="text-sm">{{ paymentDetail.name }}</div>
                  </div>
                  <section v-if="paymentDetail.va">
                    <p class="text-sm color-gray-40 mb-2">Nomor Virtual Akun</p>
                    <div class="center-content mb-3">
                      <div class="text-sm text-bold">{{ paymentDetail.va }}</div>
                      <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.va)">Copy</a>
                    </div>
                  </section>
                  <section v-if="paymentDetail.bill_key">
                    <p class="text-sm color-gray-40 mb-2">Kode Tagihan</p>
                    <div class="center-content mb-3">
                      <div class="text-sm text-bold">{{ paymentDetail.bill_code }}</div>
                      <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.bill_code)">Copy</a>
                    </div>
                    <p class="text-sm color-gray-40 mb-2">Nomor Virtual Akun</p>
                    <div class="center-content mb-3">
                      <div class="text-sm text-bold">{{ paymentDetail.bill_key }}</div>
                      <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.bill_key)">Copy</a>
                    </div>
                  </section>
                  <section v-else-if="paymentDetail.qr">
                    <p class="text-sm color-gray-40 mb-2">Scan QR</p>
                    <div class="center-content">
                      <img :src="paymentDetail.qr" alt="" class="img-fluid">
                    </div>
                    <p class="text-center mb-3"><a class="text-medium text-sm mx-2" @click="copy(paymentDetail.deepLink)">Copy</a></p>
                  </section>
                  <hr class="dashed">
                  <p class="text-sm color-gray-40 mb-2">Total pembayaran</p>
                  <div class="text-sm color-red-100 text-semiBold">{{ booking.downpayment | currency }}</div>
                  <div class="pt-5">
                    <router-link :to="'/booking-order/' + booking.salesId" class="btn btn-border-primary w-100">Lihat Detail Booking</router-link>
                    <router-link to="/home" class="w-100 text-center d-block text-sm text-medium py-4">Kembali Ke Home</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="overlay-popup ">
      <ChoosePaymentMethod/>
      <ChooseStaff :role="'terapis'"/>
    </div>

    <!-- <json-viewer :value="request" :expand-depth="5"/> -->
  </div>
</template>
<script>
import ChooseStaff from '@/components/modals/ChooseStaff'
import ChoosePaymentMethod from '@/components/modals/ChoosePaymentMethod'

export default {
  components: {
    ChooseStaff,
    ChoosePaymentMethod
  },
  data() {
    return {
      state: 'treatment',

      datePickerDobOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
      
      datePickerOptions: {
        minDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },

      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      treatmentSearch: '',

      customers: [],
      customerCurrentPage: 1,
      customerTotalPage: 1,
      customerLimit: 10,
      customerSortby: 'id.desc',
      customerSearch: '',

      memberType: 'member',
      model: {
        bookingDate: null,
        bookingTime: null,
        customer: {
          name: null,
          phoneNumber: null,
          email: null,
          dob: null,
          address: null,
          gender: null
        }
      },
      booking: null,
      selectedTreatments: [],
      selectedCustomer: null,
      selectedPaymentMethod: null,
      selectedStaff: {
        profile: {
          fullName: ""
        }
      },
      paymentDetail: {
        icon: null,
        name: null,
        bank: null,
        va: null,
        bill_code: null,
        bill_key: null,
        qr: null,
        deepLink: null,
      },
      showTimePicker: true
    }
  },
  computed: {
    timePickerOptions: function() {
      let minDate = null

      if ( this.model.bookingDate == this.$moment().format('YYYY-MM-DD') ) {
        minDate = this.$moment()
      }

      if ( minDate ) {
        return {
          minDate: minDate,
          format: 'HH:mm',
          useCurrent: true,
          stepping: 30
        }
      } else {
        return {
          format: 'HH:mm',
          stepping: 30,
        }
      }
    },
    totalPrice: function() {
      let price = 0
      this.selectedTreatments.forEach(elm => {
        price += parseInt(elm.price)
      });
      return price
    },
    totalDuration: function() {
      let duration = 0
      this.selectedTreatments.forEach(elm => {
        duration += parseInt(elm.durationHours ? elm.durationHours : 0) * 60 + parseInt(elm.durationMinutes ? elm.durationMinutes : 0)
      });
      return duration
    },
    request: function() {
      let _ = this
      const user = JSON.parse(localStorage.getItem('user'))

      let payload = {
        kfsId: user.kfsId,
        staffAccountId: this.selectedStaff ? this.selectedStaff.profile.accountId : null,
        treatments: this.selectedTreatments.map( function(x) {
          return {
            treatmentId: x.id,
            qty: 1
          }
        }),
        ..._.model.customer,
        startDate: _.$moment(_.model.bookingDate + ' ' + _.model.bookingTime).utc().format(),
        description: _.model.description,
        paymentMethod: _.selectedPaymentMethod ? _.selectedPaymentMethod.id : null,
      }

      if ( _.selectedPaymentMethod && _.selectedPaymentMethod.id != 3 ) {
        payload.bank = _.selectedPaymentMethod.bank
      }

      return payload
    }
  },
  methods: {
    getTreatment( page ) {
      let _ = this
      _.axios.get('/me/treatments'
          + '?page=' + page
          + '&limit=' + _.treatmentLimit
          + '&sortBy=' + _.treatmentSortby
          + '&search=' + _.treatmentSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.treatmentCurrentPage = data.currentPage
            _.treatmentTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.treatments.push({ ...elm, selected: false})
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchTreatment() {
      this.treatments = []
      this.getTreatment(1)
    },
    selectUnselectTreatment( event, treatment ) {
      if ( event.target.checked ) {
        this.selectedTreatments.push(treatment)
      } else {
        let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
        if ( searchSelectedTreatment != -1 ) {
          this.selectedTreatments.splice(searchSelectedTreatment, 1)
        }
      }
    },
    unselectTreatment( treatment ) {
      let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.selectedTreatments.splice(searchSelectedTreatment, 1)
      }

      let searchTreatment = this.treatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.treatments[searchTreatment].selected = false
      }
    },
    getCustomer( key ) {
      let _ = this
      _.axios.get('/customers'
        + '?page=1'
        + '&limit=' + _.customerLimit
        + '&sortBy=' + _.customerSortby
        + '&search=' + (key ? key : '')
      )
      .then( resp => {
        const data = resp.data.data
        if ( data.rows.length > 0 ) {
          _.customerCurrentPage = data.currentPage
          _.customerTotalPage = data.totalPages

          _.customers = []
          data.rows.forEach(elm => {
            _.customers.push({ ...elm, selected: false})
          });
        }
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    },
    customLabel( customer ) {
      return customer.profile.fullName + ' - ' + customer.email
    },
    convertMinute( minute ) {
      return parseInt(minute / 60) + ' Jam ' + (minute % 60) + ' Menit'
    },
    async createBookingTreatment() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.axios.post('/sales-order/booking-treatment', _.request)
        .then( resp => {
          _.booking = resp.data.data
          _.paymentDetail = _.generatePayment(_.booking.paymentDetail)
          _.state = 'finish'
        })
        .catch( err => {
          console.log( err )
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    generatePayment( payment ) {
      let data = {
        icon: null,
        name: null,
        bank: null,
        va: null,
        bill_code: null,
        bill_key: null,
        qr: null,
        deepLink: null,
      }
      if ( payment.va_numbers && payment.va_numbers.length > 0 ) { //Bank Transfer: BCA, BNI
        if ( payment.va_numbers[0].bank == 'bca' ) {
          data.icon = '/img/pay-1.png'
          data.name = 'BCA Virtual Account'
          data.bank = 'bca'
        } else if ( payment.va_numbers[0].bank == 'bni' ) {
          data.icon = '/img/pay-3.png'
          data.name = 'BNI Virtual Account'
          data.bank = 'bni'
        } else if ( payment.va_numbers[0].bank == 'bri' ) {
          data.icon = '/img/bri.png'
          data.name = 'BRI Virtual Account'
          data.bank = 'bri'
        }
        data.va = payment.va_numbers[0].va_number
      } else if ( payment.bill_key ) { //Bank Transfer: Mandiri
        data.icon = '/img/pay-2.png'
        data.name = 'Mandiri Virtual Account'
        data.bank = 'mandiri'
        data.bill_code = payment.biller_code
        data.bill_key = payment.bill_key
      } else if ( payment.actions && payment.actions.length > 0 ) { //GoPay
          data.icon = '/img/gopay.png'
          data.name = 'Gopay'
          data.qr = payment.actions[0].url
          data.deepLink = payment.actions[1].url
      } else {
        data.name = 'Tunai'
      }
      return data
    }
  },
  mounted() {
    let _ = this
    _.getTreatment(1)
    _.getCustomer()

    _.$root.$on('setPaymentMethod', (data) => {
      _.selectedPaymentMethod = data
      _.createBookingTreatment()
    })
    _.$root.$on('setStaff', (data) => {
      _.selectedStaff = data
    })
  },
  watch: {
    selectedCustomer: function() {
      let _ = this
      if ( _.selectedCustomer ) {
        _.model.customer = {
          name: _.selectedCustomer.profile.fullName,
          phoneNumber: _.selectedCustomer.profile.phone,
          email: _.selectedCustomer.email,
          dob: _.selectedCustomer.profile.bod,
          address: _.selectedCustomer.profile.address,
          gender: _.selectedCustomer.profile.gender
        }
      } else {
        _.model.customer = {
          name: null,
          phoneNumber: null,
          email: null,
          dob: null,
          address: null,
          gender: null
        }
      }
    },
    memberType: function() {
      if ( this.state == 'member' ) {
        this.selectedCustomer = null
        this.$refs.observer.reset()
      }
    },
    timePickerOptions: function() {
      this.showTimePicker = false
      setTimeout(() => {
        this.showTimePicker = true
        // this.model.bookingTime = '00:00'
      }, 100);
    }
  }
}
</script>