<template>
  <div>
    <h6 class="text-semiBold color-gray-100 text-center mb-4">Lupa Password</h6>
    <a @click="$router.back()" class="text-lg d-block text-bold mb-3"><strong><span
      class="icon-ico-back"></span></strong></a>
    <p class="text-sm color-gray-100 mb-4">Tuliskan alamat email Anda dan kami akan mengirimkan kode
      verifikasi untuk mengatur ulang kata sandi baru.</p>

    <ValidationObserver ref="observer">
      <form @submit.prevent="forgotPassword()">
        <!-- <ValidationProvider name="KFS" v-slot="{ errors }" rules="required" ref="kfsId">
          <div class="form-group auth mb-4">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">KFS</label>
            <div class="position-relative">
              <select name="" id="" class="form-select" v-model="model.kfsId">
                <option :value="null">Pilih KFS</option>
                <option v-for="kfs in kfses" :key="kfs.id" :value="kfs.id">{{ kfs.name }}</option>
              </select>
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider> -->
        <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
          <div class="form-group auth mb-4">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Email</label>
            <div class="position-relative">
              <input type="email" class="form-control" placeholder="" name="" v-model="model.email">
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <button type="submit" class="btn btn-primary w-100">Selanjutnya</button>
      </form>
    </ValidationObserver>

    <p class="text-center mb-0 mt-1"> <small class="text-danger text-sm text-xs">{{errorMsg}}</small></p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        // kfsId: null,
        email: null,
        roleId: 3
      },
      // kfses: [],
      errorMsg: null
    }
  },
  methods: {
    async forgotPassword() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return
      
      _.axios.post('/auth/forgot-password', { ..._.model, email: _.model.email.toLowerCase() })
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.push('/verification?email=' + _.model.email.toLowerCase() + '&otpToken=' + resp.data.data.otpToken + '&kfsId=' + _.model.kfsId)
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              this.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.errorMsg = err.response.data.message
          }
        })
    },
    // getKfs() {
    //   let _ = this
    //   _.axios.get('/misc/kfs')
    //     .then( resp => {
    //       _.kfses = resp.data.data
    //     })
    //     .catch( err => {
    //       _.$notify({
    //         type: 'error',
    //         title: 'Terjadi Kesalahan ('+err.response.data.code+')',
    //         text: err.response.data.message
    //       });
    //     })
    // }
  },
  mounted() {
    // this.getKfs()
  },
}
</script>