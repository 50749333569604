<template>
  <div id="payment" class="box-md hidden bg-white rounded-12 p-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="text-semiBold">Pilih Metode Pembayaran</div>
      <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
    </div>
    <div class="content-popup">
      <div class="mb-3" v-for="(paymentType, index) in paymentTypes" :key="index">
        <div class="text-sm text-medium mb-1">{{ paymentType.name }}</div>
        <a @click="setPaymentMethod(paymentMethod)" class="bank-choose" v-for="(paymentMethod, i) in paymentType.paymentMethods" :key="i">
          <div class="d-flex align-items-center">
            <div class="col-2 me-3" style="width: 45px;">
              <img :src="paymentMethod.icon" class="img-fluid">
            </div>
            <div class="text-sm color-gray-100">{{ paymentMethod.name }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      paymentTypes: [
        {
          name: "Virtual Account",
          paymentMethods: [
            {
              id: 2,
              icon: '/img/pay-1.png',
              name: 'BCA Virtual Account',
              bank: 'bca'
            },
            {
              id: 2,
              icon: '/img/pay-2.png',
              name: 'Mandiri Virtual Account',
              bank: 'mandiri'
            },
            {
              id: 2,
              icon: '/img/pay-3.png',
              name: 'BNI Virtual Account',
              bank: 'bni'
            },
            {
              id: 2,
              icon: '/img/bri.png',
              name: 'BRI Virtual Account',
              bank: 'bri'
            },
          ]
        },
        {
          name: "E-Wallet",
          paymentMethods: [
            {
              id: 3,
              icon: '/img/gopay.png',
              name: 'Gopay',
              bank: ''
            },
          ]
        },
        {
          name: "Kartu Kredit",
          paymentMethods: [
            {
              id: 4,
              icon: '/img/pay-12.png',
              name: 'Visa'
            },
          ]
        },
      ]
    }
  },
  methods: {
    setPaymentMethod( paymentMethod ) {
      this.$root.$emit('setPaymentMethod', paymentMethod)
      $('.close-popup').click()
    }
  },
}
</script>