import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Login from '../views/auth/Login.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import Verification from '../views/auth/Verification.vue'
import ResetPassword from '../views/auth/ResetPassword.vue'

import Home from '../views/home/Index.vue'
import BookingTreatment from '../views/home/BookingTreatment.vue'
import SalesOrder from '../views/home/SalesOrder.vue'
import Promo from '../views/home/Promo.vue'

import BookingOrderIndex from '../views/booking_order/Index.vue'
import BookingOrderDetail from '../views/booking_order/Detail.vue'

import SalesOrderIndex from '../views/sales_order/Index.vue'
import SalesOrderDetail from '../views/sales_order/Detail.vue'
import SalesOrderMedicalRecord from '../views/sales_order/MedicalRecord.vue'
import SalesOrderNewInvoice from '../views/sales_order/NewInvoice.vue'

import InventoryIndex from '../views/inventory/Index.vue'

import CustomerIndex from '../views/customer/Index.vue'
import CustomerForm from '../views/customer/Form.vue'
import CustomerDetail from '../views/customer/detail/Index.vue'

import SettingIndex from '../views/setting/Index.vue'
import InformationIndex from '../views/information/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: () => import ('../layouts/Main.vue'),
    children: [
      {
          path: '/',
          redirect: '/home'
      },
      {
          path: '/home',
          name: 'Home',
          component: Home,
          meta: {
            navbarTitle: 'Beranda'
          }
      },
      {
          path: '/home/booking-treatment',
          name: 'BookingTreatment',
          component: BookingTreatment,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/home/sales',
          name: 'SalesOrder',
          component: SalesOrder,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/home/promo',
          name: 'Promo',
          component: Promo,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/booking-order',
          name: 'BookingOrderIndex',
          component: BookingOrderIndex,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/booking-order/:id',
          name: 'BookingOrderDetail',
          component: BookingOrderDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/sales-order',
          name: 'SalesOrderIndex',
          component: SalesOrderIndex,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/sales-order/:id',
          name: 'SalesOrderDetail',
          component: SalesOrderDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/sales-order/:id/medical-record/:id_customer',
          name: 'SalesOrderMedicalRecord',
          component: SalesOrderMedicalRecord,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/sales-order/:id/new-invoice',
          name: 'SalesOrderNewInvoice',
          component: SalesOrderNewInvoice,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/inventory',
          name: 'InventoryIndex',
          component: InventoryIndex,
          meta: {
            navbarTitle: 'Inventory'
          }
      },
      {
          path: '/customer',
          name: 'CustomerIndex',
          component: CustomerIndex,
          meta: {
            navbarTitle: 'Customer Management'
          }
      },
      {
          path: '/customer/add',
          name: 'CustomerForm',
          component: CustomerForm,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/customer/:id',
          name: 'CustomerDetail',
          component: CustomerDetail,
          meta: {
            navbarTitle: ''
          }
      },
      {
          path: '/setting',
          name: 'SettingIndex',
          component: SettingIndex,
          meta: {
            navbarTitle: 'Pengaturan'
          }
      },
      {
          path: '/information',
          name: 'InformationIndex',
          component: InformationIndex,
          meta: {
            navbarTitle: 'Pusat Informasi'
          }
      },
    ]
  },
  {
    path: '',
    component: () => import ('../layouts/Authentication.vue'),
    children: [
      {
          path: '/login',
          name: 'Login',
          component: Login,
          meta: {
            isGuest: true
          }
      },
      {
          path: '/forgot-password',
          name: 'ForgotPassword',
          component: ForgotPassword,
          meta: {
            isGuest: true
          }
      },
      {
          path: '/verification',
          name: 'Verification',
          component: Verification,
          meta: {
            isGuest: true
          }
      },
      {
          path: '/reset-password',
          name: 'ResetPassword',
          component: ResetPassword,
          meta: {
            isGuest: true
          }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if ( to.meta.isGuest ) {
    next()
  } else if ( !store.state.accessToken && !localStorage.getItem('accessToken') ) {
    next({name: 'Login'})
  } else {
    next()
  }
})

export default router
