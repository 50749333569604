<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span> Detail Booking
    </a>

    <div class="row ">
      <div class="col-lg-7 mb-4">
        <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3 mb-3">
          <div class="d-flex align-items-center">
            <div class="bullet" :class="'bg-' + boStatusColor(model.status)"></div>
            <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + boStatusColor(model.status)">{{ boStatusText(model.status) }}</div>
          </div>
          <div class="text-xs text-italic color-gray-80" v-if="model.status == -3">{{ model.booking.canceledReason }}</div>
        </div>
        <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3">
          <div class="d-flex justify-content-between mb-3">
            <div class="col-auto">
              <div class="text-xs color-gray-40">Nama Kustomer</div>
            </div>
            <div class="col-8">
              <!-- <div class="text-xs text-medium text-right d-block">{{ model.booking && model.booking.bookingProfile ? model.booking.bookingProfile.name : '-'}}</div> -->
              <div class="text-xs text-medium text-right d-block">{{ model.customer.profile.fullName }}</div>
            </div>
          </div>

          <div class="d-flex justify-content-between mb-3">
            <div class="col-auto">
              <div class="text-xs color-gray-40">Treatment</div>
            </div>
            <div class="col-8">
              <div class="text-xs text-medium text-right d-block">{{ model.treatments.map( x => x.treatment.name ).join(", ") }}</div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-xs color-gray-40">Staff</div>
            <div class="text-xs text-medium">{{ model.staff ? model.staff.profile.fullName : '-'}}</div>
          </div>

          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-xs color-gray-40">Keluhan</div>
            <div class="text-xs text-medium">{{ model.description ? model.description : '-' }}</div>
          </div>

          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-xs color-gray-40">Harga</div>
            <div class="text-xs text-medium">{{ totalPriceTreatment | currency }}</div>
          </div>

          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-xs color-gray-40">Durasi</div>
            <div class="text-xs text-medium">{{ convertMinute(totalDuration) }}</div>
          </div>

          <div class="d-flex justify-content-between align-items-center ">
            <div class="text-xs color-gray-40">Waktu Booking</div>
            <div class="text-xs text-medium">{{ model.startDate | moment('DD MMM YYYY, HH:mm')}} WIB</div>
          </div>
        </div>
      </div>
      <!--End Form-->
      
      <div class="col-lg-5">
        <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3" v-if="model.status == -2 && model.booking && model.booking.paymentDetail">
          <p class="text-sm color-gray-40">Segera lakukan pembayaran sebelum</p>
          <div class="text-sm color-red-100 text-semiBold">{{ model.booking.paymentDetail.expired_payment_time | moment('DD MMMM YYYY, HH:mm')}} WIB</div>
          <hr class="dashed">
          <p class="text-sm color-gray-40 mb-2">Metode Pembayaran</p>
          <div class="mb-3 d-flex">
            <div class="me-2"><img :src="paymentDetail.icon" class="img-fluid" style="max-width: 45px;"></div>
            <div class="text-sm">{{ paymentDetail.name }}</div>
          </div>
          <section v-if="paymentDetail.va">
            <p class="text-sm color-gray-40 mb-2">Nomor Virtual Akun</p>
            <div class="mb-3">
              <div class="text-sm text-bold">{{ paymentDetail.va }} <a class="text-medium text-sm" @click="copy(paymentDetail.va)">Copy</a></div>
            </div>
          </section>
          <section v-if="paymentDetail.bill_key">
            <p class="text-sm color-gray-40 mb-2">Kode Tagihan</p>
            <div class="mb-3 d-flex">
              <div class="text-sm text-bold">{{ paymentDetail.bill_code }}</div>
              <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.bill_code)">Copy</a>
            </div>
            <p class="text-sm color-gray-40 mb-2">Nomor Virtual Akun</p>
            <div class="mb-3 d-flex">
              <div class="text-sm text-bold">{{ paymentDetail.bill_key }}</div>
              <a class="text-medium text-sm mx-2" @click="copy(paymentDetail.bill_key)">Copy</a>
            </div>
          </section>
          <section v-else-if="paymentDetail.qr">
            <p class="text-sm color-gray-40 mb-2">Scan QR</p>
            <div class="d-flex">
              <img :src="paymentDetail.qr" alt="" class="img-fluid w-50">
              <a class="text-medium text-sm align-self-center" @click="copy(paymentDetail.deepLink)">Copy</a>
            </div>
          </section>
          <hr class="dashed">
          <p class="text-sm color-gray-40 mb-2">Total pembayaran</p>
          <div class="text-sm color-red-100 text-semiBold">{{ model.booking.downpayment | currency }}</div>
        </div>
        <div class="text-sm text-semiBold mb-2">Rincian Pembayaran</div>
        <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3" v-if="model.booking">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="text-xs color-gray-40">Metode Pembayaran</div>
            <div class="text-xs text-medium">{{ model.booking.paymentDetail ? paymentDetail.name : '-' }}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="text-xs color-gray-40">Down Payment 30%</div>
            <div class="text-xs text-medium">{{ model.booking.downpayment | currency }}</div>
          </div>
          <hr class="dashed">
          <div class="d-flex justify-content-between align-items-center ">
            <div class="text-xs color-gray-40">Sisa Pembayaran</div>
            <div class="text-xs text-medium color-red-100">{{ model.invoice.totalPayment | currency}}</div>
          </div>
        </div>

        <button class="btn btn-primary w-100 btn-staff" type="button" v-if="[0].includes(model.status)">Pilih Staff</button>
        <div class="row gx-3" v-else>
          <div class="col" v-if="[-2,-1].includes(model.status)">
            <button class="btn btn-border-primary w-100 btn-staff" type="button">Batalkan</button>
          </div>
          <div class="col" v-if="[-2].includes(model.status)">
            <button class="btn btn-primary w-100" type="button" @click="downloadInvoice()">Download Invoice</button>
          </div>
          <div class="col" v-if="[-1].includes(model.status)" @click="updateStatus(1)">
            <a class="btn btn-primary w-100">Mulai</a>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay-popup">
      <div id="staff" class="box-xs hidden bg-white rounded-12 p-3" v-if="[-2,-1].includes(model.status)">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Batalkan</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup mb-3">
          <div class="position-relative">
            <div class="choose-staff">
              <input type="radio" class="hidden" id="staff-1" name="staff" checked v-model="canceledReason" 
                :value="'Kustomer melewati batas jam booking'">
              <label for="staff-1" class="cursor-pointer"><span class="ms-4">Kustomer melewati batas jam booking</span>
              </label>
            </div>
          </div>

          <div class="position-relative">
            <div class="choose-staff">
              <input type="radio" class="hidden" id="staff-2" name="staff" v-model="canceledReason" 
                :value="'Kustomer meminta dibatalkan'">
              <label for="staff-2" class="cursor-pointer"><span class="ms-4">Kustomer meminta dibatalkan</span> </label>
            </div>
          </div>

          <div class="position-relative">
            <div class="choose-staff">
              <input type="radio" class="hidden" id="staff-3" name="staff" v-model="canceledReason" 
                :value="'Salah input invoice'">
              <label for="staff-3" class="cursor-pointer"><span class="ms-4">Salah input invoice</span> </label>
            </div>
          </div>
        </div>
        <div class="row gx-2">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Kembali</button>
          </div>
          <div class="col-6">
            <button class="btn btn-border-primary w-100 close-btn" type="button" @click="cancel()">Kirim</button>
          </div>
        </div>
      </div>
      <ChooseStaff :role="'terapis'" v-else-if="[0].includes(model.status)"/>
    </div>
  </div>
</template>

<script>
import ChooseStaff from '@/components/modals/ChooseStaff.vue'
export default {
  components: {
    ChooseStaff
  },
  data() {
    return {
      model: {
        customer: {
          profile: {}
        },
        treatments: [],
        staff: {
          profile: {}
        },
        invoice: {},
        booking: {
          paymentDetail: {}
        }
      },
      selectedStaff: {
        profile: {
          fullName: ""
        }
      },
      paymentDetail: {
        icon: null,
        name: null,
        bank: null,
        va: null,
        bill_code: null,
        bill_key: null,
        qr: null,
        deepLink: null,
      },
      canceledReason: 'Kustomer melewati batas jam booking'
    }
  },
  computed: {
    totalDuration: function() {
      let duration = 0
      this.model.treatments.forEach(elm => {
        duration += parseInt(elm.durationHours ? elm.durationHours : 0) * 60 + parseInt(elm.durationMinutes ? elm.durationMinutes : 0)
      });
      return duration
    },
    totalPriceTreatment: function() {
      let price = 0
      this.model.treatments.forEach(elm => {
        price += parseInt(elm.price)
      });
      return price
    },
  },
  methods: {
    showBooking() {
      let _ = this

      _.axios.get('/sales-order/booking-treatment/' + _.$route.params.id)
        .then( resp => {
          _.model = resp.data.data
          if ( _.model.booking && _.model.booking.paymentDetail ) {
            _.paymentDetail = _.generatePayment(_.model.booking.paymentDetail)
          }
          // _.model.status = 0
        })
        .catch( err => {
          console.log(err)
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    updateStatus( status ) {
      let _ = this

      _.axios.put('/sales-order/update-status/' + _.$route.params.id, { status: status })
        .then(() => {
          if ( status === 1 ) {
            _.$router.push('/sales-order/' + _.$route.params.id)
          } else {
            _.showBooking()
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    convertMinute( minute ) {
      return parseInt(minute / 60) + ' Jam ' + (minute % 60) + ' Menit'
    },
    generatePayment( payment ) {
      let data = {
        icon: null,
        name: null,
        bank: null,
        va: null,
        bill_code: null,
        bill_key: null,
        qr: null,
        deepLink: null,
      }
      if ( payment.va_numbers && payment.va_numbers.length > 0 ) { //Bank Transfer: BCA, BNI
        if ( payment.va_numbers[0].bank == 'bca' ) {
          data.icon = '/img/pay-1.png'
          data.name = 'BCA Virtual Account'
          data.bank = 'bca'
        } else if ( payment.va_numbers[0].bank == 'bni' ) {
          data.icon = '/img/pay-3.png'
          data.name = 'BNI Virtual Account'
          data.bank = 'bni'
        } else if ( payment.va_numbers[0].bank == 'bri' ) {
          data.icon = '/img/bri.png'
          data.name = 'BRI Virtual Account'
          data.bank = 'bri'
        }
        data.va = payment.va_numbers[0].va_number
      } else if ( payment.bill_key ) { //Bank Transfer: Mandiri
        data.icon = '/img/pay-2.png'
        data.name = 'Mandiri Virtual Account'
        data.bank = 'mandiri'
        data.bill_code = payment.biller_code
        data.bill_key = payment.bill_key
      } else if ( payment.actions && payment.actions.length > 0 ) { //GoPay
          data.icon = '/img/gopay.png'
          data.name = 'Gopay'
          data.qr = payment.actions[0].url
          data.deepLink = payment.actions[1].url
      } else {
        data.name = 'Tunai'
      }
      return data
    },
    cancel() {
      let _ = this

      _.axios.put('/sales-order/booking-treatment/cancel', { id: _.$route.params.id, canceledReason: _.canceledReason })
        .then(() => {
          _.showBooking()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    assignStaff() {
      let _ = this

      let payload = {
        salesId: _.$route.params.id,
        staffAccountId: _.selectedStaff.profile.accountId
      }
      _.axios.put('/sales-order/booking-treatment/assign-staff', payload)
        .then(() => {
          _.updateStatus(-1)
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    downloadInvoice() {
      let _ = this

      _.axios.get('/invoice/export/' + _.model.invoice.id + '/booking'
        ,{responseType: 'arraybuffer'})
        .then(resp => {
            let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            let link        = document.createElement('a')
            link.href       = window.URL.createObjectURL(blob)
            link.download   = 'INVOICE-' + _.model.noOrder + '.pdf'
            link.click()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    }
  },
  mounted() {
    let _ = this
    _.showBooking()
    
    _.$root.$on('setStaff', (data) => {
      _.selectedStaff = data
      _.assignStaff()
    })
  },
  beforeDestroy() {
    this.$root.$off('setStaff')
  },
  watch: {
    '$route.params.id': function() {
      this.showBooking()
    }
  }
}
</script>