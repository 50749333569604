<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span> Medical Record
    </a>

    <div class="row gx-3">
      <div class="col-sm-6 col-lg-4" v-for="medicalRecord in medicalRecords" :key="medicalRecord.id">
        <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3 mb-3">
          <div class="text-sm color-gray-60">{{ medicalRecord.createdAt | moment('DD MMM YYYY')}}</div>
          <hr class="dashed">
          <div class="text-sm text-medium mb-3">{{ medicalRecord.treatments }}</div>
          <div class="mb-3">
            <div class="text-xs color-gray-60 mb-2">Keluhan</div>
            <div class="text-sm text-medium ">{{ medicalRecord.description ? medicalRecord.description : '-' }}</div>
          </div>
          <div class="mb-3">
            <div class="text-xs color-gray-60 mb-2">Rekomendasi </div>
            <div class="text-sm text-medium text-hidden">{{ medicalRecord.recomendation ? medicalRecord.recomendation : '-' }}</div>
          </div>
          <div class="mb-0">
            <div class="text-xs color-gray-60 mb-2">Catatan</div>
            <div class="text-sm text-medium text-hidden">{{ medicalRecord.notes ? medicalRecord.notes : '-' }}</div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center text-sm" v-if="medicalRecords.length == 0">Tidak ada medical record</p>
    <p class="text-center text-sm" v-if="medicalRecords.length > 0 && medicalRecordCurrentPage < medicalRecordTotalPage">
      <a class="color-gray-100" @click="getMedicalRecord( medicalRecordCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      medicalRecords: [],
      medicalRecordCurrentPage: 1,
      medicalRecordTotalPage: 1,
      medicalRecordLimit: 10,
      medicalRecordSortby: 'id.desc',
    }
  },
  methods: {
    getMedicalRecord( page ) {
      let _ = this
      _.axios.get('/customers/' + _.$route.params.id_customer + '/medical-histories'
          + '?page=' + page
          + '&limit=' + _.medicalRecordLimit
          + '&sortBy=' + _.medicalRecordSortby
          + '&status=2'
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.medicalRecordCurrentPage = data.currentPage
            _.medicalRecordTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.medicalRecords.push({ ...elm, selected: false})
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.getMedicalRecord(1)
  },
}
</script>