<template>
  <div class="col-lg-4 col-sm-6 mb-4">
    <router-link :to="'/sales-order/' + data.id" class="bg-white d-block rounded-12 p-3">
      <div class="d-flex align-items-center mb-3">
        <div class="bullet" :class="'bg-' + boStatusColor(data.status)"></div>
        <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + boStatusColor(data.status)">{{ boStatusText(data.status )}}</div>
      </div>
      <ul class="item-info">
        <li>{{ data.noOrder }}</li>
        <li v-if="data.treatmentName && data.products.length > 0">
          {{ data.treatmentName + ', ' + data.products.filter(x => x.product).map( x => x.product.name ).join(", ") }}
        </li>
        <li v-else-if="data.treatmentName">
          {{ data.treatmentName }}
        </li>
        <li v-else-if="data.products.length > 0">
          {{ data.products.filter(x => x.product).map( x => x.product.name ).join(", ") }}
        </li>
      </ul>
      <hr class="dashed">
      <div class="text-sm text-medium color-gray-100 text-hidden">{{ data.customerName }} - {{ data.startDate | moment('dddd DD MMMM YYYY, HH:mm') }} WIB</div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: ['data'],
}
</script>