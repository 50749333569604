<template>
  <div>
    <section v-if="state == 'sales-order'">
      <a @click="$router.back()" v-if="!isFromMember" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Sales Order</a>
      <a @click="state = 'member'" v-else class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Sales Order</a>

      <div class="row gx-xl-5">
        <div class="col-lg-7 mb-4">
          <div class="position-relative mb-3" v-show="childState == 'treatment'">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchTreatment" placeholder="Cari Treatment" v-model="treatmentSearch">
            </div>
          </div>
          <div class="position-relative mb-3" v-show="childState == 'produk'">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchProduct" placeholder="Cari Produk" v-model="productSearch">
            </div>
          </div>

          <ul class="nav nav-tabs border-0 mb-3" id="tabTop" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="childState == 'treatment'? 'active' : ''" id="all" data-bs-toggle="tab" data-bs-target="#all-pane" type="button"
                role="tab" aria-controls="all-pane" aria-selected="true" @click="childState = 'treatment'">Treatment</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="childState == 'produk'? 'active' : ''" id="waiting-tab" data-bs-toggle="tab" data-bs-target="#waiting-tab-pane"
                type="button" role="tab" aria-controls="waiting-tab-pane" aria-selected="false" @click="childState = 'produk'">Produk</button>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="childState == 'treatment'? 'show active' : ''" id="all-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="0">
              <div v-for="treatment in treatments" :key="treatment.id"
                class="position-relative border-1 border-solid border-color-gray rounded-12 bg-white deco-treatment me-2 me-lg-0 p-3 mb-3">
                <div class="d-flex">
                  <div class="me-3">
                    <img :src="treatment.collectionImage && treatment.collectionImage.length > 0 ? treatment.collectionImage[0] : '/img/user-1.jpg'" class="img-48">
                  </div>
                  <div class="col">
                    <div class="text-medium mb-1">{{ treatment.name }}</div>
                    <ul class="item-info mb-0">
                      <li><span>{{ treatment.durationHours ? treatment.durationHours : 0 }} Jam {{ treatment.durationMinutes ? treatment.durationMinutes : 0 }} Mnt</span></li>
                      <li>
                        <div class="color-red-100 text-semiBold">{{ treatment.price | currency }}</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="choose-treatment">
                  <input type="checkbox" name="treatment" class="hidden" :id="'treatment-' + treatment.id" 
                    :checked="selectedTreatments.map( x => x.id ).indexOf(treatment.id) != -1"
                    @change="selectUnselectTreatment($event, treatment)">
                  <label :for="'treatment-' + treatment.id" class="cursor-pointer"></label>
                </div>
              </div>
              <p class="text-center text-sm" v-if="treatments.length == 0">Tidak ada treatment</p>
              <p class="text-center text-sm" v-if="treatments.length > 0 && treatmentCurrentPage < treatmentTotalPage">
                <a class="color-gray-100" @click="getTreatment( treatmentCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
            <div class="tab-pane fade" :class="childState == 'produk'? 'show active' : ''" id="waiting-tab-pane" role="tabpanel" aria-labelledby="waiting-tab" tabindex="0">
              <div class="d-grid grid-product gap-3">
                <div v-for="product in products" :key="product.id"
                  class="bg-white border-1 border-solid border-color-gray rounded-12 p-3">
                  <div class="img-product center-content mb-3">
                    <img :src="product.product && product.product.detailItemImage.length > 0 ? product.product.detailItemImage[0] : '/img/product-1.png'" class="img-fluid">
                  </div>
                  <div class="text-xs text-medium text-center px-2 mb-2">{{ product.productName }}</div>
                  <div class="text-xs color-gray-60 text-center mb-2">Stok {{ product.stock | numFormat }}</div>
                  <div class="text-xs color-red-100 text-semiBold text-center mb-2">{{ product.price | currency }}</div>
                  <div class="d-flex justify-content-center align-items-center">
                    <button @click="product.qty -= 1, addOrSubProduct(product, 'sub')" :disabled="product.qty == 0"
                      class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                    </button>
                    <input type="number" class="form-control input-number" :min="0" :max="product.stock" :disabled="product.stock == 0"
                      v-model="product.qty" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                    <button @click="product.qty += 1, addOrSubProduct(product, 'add')" :disabled="product.qty >= product.stock"
                      class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                    </button>
                  </div>
                </div>
                <!--End List Product-->
              </div>
              <p class="text-center text-sm" v-if="products.length == 0">Tidak ada produk</p>
              <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
                <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
          </div>
        </div>
        <!--End List Treatment-->

        <div class="col-lg-5">
          <div class="accordion mb-3">
            <div class="accordion-item  border-1 border-solid border-color-gray">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button justify-content-between text-sm color-gray-100 collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ totalSelected }} Terpilih</span>
                      <span class="color-red-100 text-semiBold me-2 text-xs">{{ totalPrice | currency }}</span>
                    </div>
                  </div>
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne">
                <div class="d-flex justify-content-between align-items-center p-4"
                  v-for="treatment in selectedTreatments" :key="treatment.id">
                  <div class="text-sm">{{ treatment.name }}</div>
                  <div class="col-auto">
                    <div class="d-flex">
                      <div class="text-xs color-red-100 text-semiBold">{{ treatment.price | currency }}</div>
                      <button class="btn p-0 ms-2" type="button" @click="unselectTreatment(treatment)">
                        <span class="icon-ico-minus-circle color-gray-60"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center py-3 px-4"
                  v-for="product in selectedProducts" :key="product.id">
                  <div>
                    <div class="text-xs text-medium mb-1">{{ product.productName }}</div>
                    <div class="text-xs text-semiBold color-red-100">{{ product.price | currency }}</div>
                  </div>
                  <div class="col-auto">
                    <div class="d-flex justify-content-center align-items-center">
                      <button @click="product.qty -= 1, addOrSubSelectedProduct(product, 'sub')" :disabled="product.qty == 0"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                      </button>
                      <input type="number" class="form-control input-number" :min="0" :max="product.stock"
                        v-model="product.qty" @change="handleCustomQtySelectedProduct($event, product)" @keypress="numbersOnly">
                      <button @click="product.qty += 1, addOrSubSelectedProduct(product, 'add')" :disabled="product.qty >= product.stock"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button @click="state = 'member'" class="btn btn-primary w-100" :disabled="totalSelected == 0">Selanjutnya</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    
    <section v-else-if="state == 'member'">
      <a @click="state = 'sales-order'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Sales Order</a>
      
      <ValidationObserver ref="observer">
        <form @submit.prevent="goToPayment()">
          <div class="row gx-xl-5">
            <div class="col-lg-7 mb-4">
              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="text-xs color-gray-40">Nama Kustomer</div>
                  <div class="text-xs text-medium">{{ model.customer.profile.fullName }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="text-xs color-gray-40">Staff</div>
                  <div class="text-xs text-medium">{{ model.staff.profile.fullName }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="text-xs color-gray-40">Keluhan</div>
                  <div class="text-xs text-medium">{{ model.description }}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-xs color-gray-40">Waktu Booking</div>
                  <div class="text-xs text-medium">{{ model.startDate | moment('DD MMM YYYY, HH:mm') }}</div>
                </div>
              </div>
            </div>
            <!--End Form-->

            <div class="col-lg-5">
              <div class="text-sm text-semiBold mb-2">Rincian Order</div>
              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <section v-if="selectedTreatments.length > 0">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="col-auto">
                      <div class="text-xs color-gray-40">Treatment</div>
                    </div>
                    <div class="col-8">
                      <div class="text-xs text-medium text-right d-block" v-if="selectedTreatments.length > 0">{{ selectedTreatments.map( x => x.name ).join(", ") }}</div>
                      <div class="text-xs text-medium text-right d-block" v-else>-</div>
                    </div>

                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="text-xs color-gray-40">Harga</div>
                    <div class="text-xs text-medium">{{ totalPriceTreatment | currency }}</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="text-xs color-gray-40">Durasi</div>
                    <div class="text-xs text-medium" v-if="selectedTreatments.length > 0">{{ convertMinute(totalDuration) }}</div>
                    <div class="text-xs text-medium" v-else>-</div>
                  </div>
                </section>
                <div class="d-flex justify-content-between align-items-center ">
                  <div class="text-xs color-gray-40">Waktu Order</div>
                  <div class="text-xs text-medium">{{ model.startDate | moment('DD MMM YYYY, HH:mm')}} WIB</div>
                </div>

                <section v-if="selectedProducts.length > 0">
                  <hr class="dashed">
                  <div class="d-flex justify-content-between align-items-center ">
                    <div class="text-xs color-gray-40">{{selectedProducts.length | numFormat}} Produk <a class="ms-3 open-product">Lihat Produk</a></div>
                    <div class="text-xs text-medium">{{ totalPriceProduct | currency }}</div>
                  </div>
                </section>
              </div>
              
              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-xs color-gray-40">Total Bayar</div>
                  <div class="text-xs text-semiBold color-red-100">{{ totalPrice | currency }}</div>
                </div>
              </div>

              <!-- <button class="btn btn-primary w-100 btn-pay" type="button" v-if="!invalid">Pilih Metode Pembayaran</button>
              <button class="btn btn-primary w-100" type="submit" v-else>Pilih Metode Pembayaran</button> -->
              <div class="row gx-3">
                <div class="col-6">
                  <button @click="state = 'sales-order', childState = 'produk', isFromMember = true" class="btn btn-primary w-100" type="button">Tambah Produk</button>
                </div>
                <div class="col-6">
                  <button class="btn btn-border-primary w-100" type="submit" :disabled="loading">Simpan</button>
                </div>
              </div>
            </div>
            <!--End Right Side Bar-->
          </div>
        </form>
      </ValidationObserver>
    </section>

    <!-- <json-viewer :value="request" :expand-depth="5"></json-viewer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      state: 'sales-order',
      childState: 'treatment',
      isFromMember: false,

      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      treatmentSearch: '',

      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',

      selectedTreatments: [],
      selectedProducts: [],

      model: {

      },
      kun: [],
      loading: false
    }
  },
  computed: {
    totalPriceTreatment: function() {
      let price = 0
      this.selectedTreatments.forEach(elm => {
        price += parseInt(elm.price)
      });
      return price
    },
    totalPriceProduct: function() {
      let price = 0
      this.selectedProducts.forEach(elm => {
        price += parseInt(elm.price) * elm.qty
      });
      return price
    },
    totalPrice: function() {
      return this.totalPriceTreatment + this.totalPriceProduct
    },
    totalPriceFinal: function() {
      return this.totalPriceTreatment + this.totalPriceProduct
    },
    totalSelected: function() {
      return this.selectedTreatments.length + this.selectedProducts.length
    },
    totalDuration: function() {
      let duration = 0
      this.selectedTreatments.forEach(elm => {
        duration += parseInt(elm.durationHours ? elm.durationHours : 0) * 60 + parseInt(elm.durationMinutes ? elm.durationMinutes : 0)
      });
      return duration
    },
    request: function() {
      let _ = this

      return {
        treatments: this.selectedTreatments.map( function(x) {
          return {
            treatmentId: x.id,
            qty: 1
          }
        }),
        products: this.selectedProducts.map( function(x) {
          return {
            accuProductId: x.accuId,
            qty: x.qty,
            unitPrice: parseInt(x.price),
          }
        }),
      }
    },
  },
  methods: {
    getTreatment( page ) {
      let _ = this
      _.axios.get('/me/treatments'
          + '?page=' + page
          + '&limit=' + _.treatmentLimit
          + '&sortBy=' + _.treatmentSortby
          + '&search=' + _.treatmentSearch
        )
        .then( resp => {
          const data = resp.data.data

          _.treatmentCurrentPage = data.currentPage
          _.treatmentTotalPage = data.totalPages

          if ( _.treatmentLimit != data.count ) {
            _.treatmentLimit = data.count
            _.getTreatment(1)
            return;
          }

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.treatments.push({ ...elm, selected: false})
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getProduct( page ) {
      let _ = this
      _.axios.get('/inventory/product'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
        )
        .then( resp => {
          const data = resp.data.data

          _.productCurrentPage = data.currentPage
          _.productTotalPage = data.totalPages

          if ( _.productLimit != data.count ) {
            _.productLimit = data.count
            _.getProduct(1)
            return;
          }

          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.products.push({ ...elm, qty: 0 })
            });

            for (let i = 0; i < _.kun.length; i++) {
              const selectedProduct = _.kun[i];
              let searchProduct = _.products.map( z => z.accuId ).indexOf( selectedProduct.accuId )
              if (searchProduct != -1 ) {
                _.selectedProducts.push({..._.products[searchProduct], qty: selectedProduct.qty})
              }
            }

            _.products = []
            data.rows.forEach(elm => {
              let qty = 0
              let searchProduct = _.selectedProducts.map( z => z.id ).indexOf( elm.id )
              if ( searchProduct != -1 ) {
                elm.stock = elm.stock + _.selectedProducts[searchProduct].qty

                if ( elm.stock >= _.selectedProducts[searchProduct].qty ) {
                  qty = _.selectedProducts[searchProduct].qty
                } else {
                  _.selectedProducts.splice(searchProduct, 1)
                }
              }
              _.products.push({ ...elm, qty: qty })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchTreatment() {
      this.treatments = []
      this.getTreatment(1)
    },
    searchProduct() {
      let _ = this
      _.products = []
      _.getProduct(1)
    },
    selectUnselectTreatment( event, treatment ) {
      if ( event.target.checked ) {
        this.selectedTreatments.push(treatment)
      } else {
        let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
        if ( searchSelectedTreatment != -1 ) {
          this.selectedTreatments.splice(searchSelectedTreatment, 1)
        }
      }
    },
    unselectTreatment( treatment ) {
      let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.selectedTreatments.splice(searchSelectedTreatment, 1)
      }

      let searchTreatment = this.treatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.treatments[searchTreatment].selected = false
      }
    },
    convertMinute( minute ) {
      return parseInt(minute / 60) + ' Jam ' + (minute % 60) + ' Menit'
    },
    addOrSubProduct( product, type ) {
      let _ = this

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchSelectedProduct != -1 ) {
          _.selectedProducts[searchSelectedProduct].qty += 1
        } else {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.selectedProducts[searchSelectedProduct].qty == 1 ) {
          _.selectedProducts.splice(searchSelectedProduct, 1)
        } else {
          _.selectedProducts[searchSelectedProduct].qty -= 1
        }
      }
    },
    addOrSubSelectedProduct( product, type ) {
      let _ = this

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchProduct != -1 ) {
          _.products[searchProduct].qty += 1
        } else {
          _.products.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.products[searchProduct].qty == 1 ) {
          let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
          _.selectedProducts.splice(searchSelectedProduct, 1)
          $('.close-popup').click()
        }
        _.products[searchProduct].qty -= 1
      }
    },
    handleCustomQty( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.stock < event.target.value ) {
        product.qty = parseInt( product.stock )
      }

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
      if ( searchSelectedProduct != -1 ) {
        _.selectedProducts[searchSelectedProduct].qty = product.qty
        if (product.qty == 0) {
          _.selectedProducts.splice(searchSelectedProduct, 1)
        }
      } else {
        if (product.qty > 0) {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      }
    },
    handleCustomQtySelectedProduct( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.stock < event.target.value ) {
        product.qty = parseInt( product.stock )
      }

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )
      if ( searchProduct != -1 ) {
        _.products[searchProduct].qty = product.qty
        if (product.qty == 0) {
          let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
          _.selectedProducts.splice(searchSelectedProduct, 1)
          $('.close-popup').click()
        }
      }
    },
    goToPayment() {
      let _ = this

      _.loading = true
      _.axios.put('/sales-order/' + _.$route.params.id, _.request)
        .then(() => {
          _.$router.push('/sales-order/' + _.$route.params.id)
          _.loading = false
        })
        .catch( err => {
          _.loading = false
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    showOrder() {
      let _ = this

      _.axios.get('/sales-order/' + _.$route.params.id)
        .then( resp => {
          _.model = resp.data.data

          _.selectedTreatments = _.model.treatments.map( x => x.treatment )
          _.kun = _.model.products.map( function(x) {
            return {
              accuId: x.accuProductId,
              qty: x.qty
            }
          } )

          _.getTreatment(1)
          _.getProduct(1)
        })
        .catch( err => {
          console.log(err)
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    let _ = this
    _.showOrder()
  },
  watch: {
    state: function() {
      if ( this.state == 'member' ) {
        this.isFromMember = false
      }
    }
  }
}
</script>