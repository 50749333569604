<template>
  <div id="filter" class="box-md hidden bg-white rounded-12 p-3">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="text-semiBold">Filter</div>
      <button class="btn close-popup p-0" type="button" v-if="isFiltered"><span class="icon-ico-close"></span></button>
      <button class="btn close-popup p-0" type="button" v-else @click="resetFilter()"><span class="icon-ico-close"></span></button>
    </div>
    <div class="content-popup">
      <div class="row gx-3">
        <div class="col-sm-6 mb-3">
          <label class="text-xs color-gray-40 mb-2">Tanggal Mulai</label>
          <div class="position-relative">
            <div class=" icn-calendar">
              <date-picker v-model="filter.startDate" :config="datePickerStartDateOptions" placeholder="Pilih Tanggal"></date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-6 mb-3">
          <label class="text-xs color-gray-40 mb-2">Tanggal Akhir</label>
          <div class="position-relative">
            <div class=" icn-calendar">
              <date-picker v-model="filter.endDate" :config="datePickerEndDateOptions" placeholder="Pilih Tanggal"></date-picker>
            </div>
          </div>
        </div>
        <div class="col-sm-12 mb-3">
          <label class="text-xs color-gray-40 mb-2">Layanan</label>
          <!-- <div class="position-relative">
            <div class="icn-chevron">
              <select class="form-select" v-model="filter.treatmentId">
                <option :value="null">Pilih Layanan</option>
                <option>Layanan 1</option>
                <option>Layanan 2</option>
                <option>Layanan 3</option>
              </select>
            </div>
          </div> -->
          <multiselect 
            track-by="id" 
            placeholder="Pilih Treatment"
            v-model="selectedTreatment"
            @search-change="getTreatment"
            :options="treatments"
            :custom-label="customLabel"
            :multiple="false" 
            :clear-on-select="false" 
            :close-on-select="true"
            :internal-search="false">
            <span slot="noResult">Maaf, Treatment tidak ditemukan</span>
          </multiselect>
        </div>
        <div class="col-6">
          <button class="btn btn-border-primary w-100 close-btn" type="" @click="resetFilter()">Reset</button>
        </div>
        <div class="col-6">
          <button class="btn btn-primary w-100 close-btn" type="" @click="submitFilter()">Terapkan</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      selectedTreatment: null,

      filter: {
        startDate: null,
        endDate: null,
        treatmentId: null
      },

      datePickerStartDateOptions: {
        format: 'YYYY-MM-DD',
        useCurrent: false,
      },
      isFiltered: false
    }
  },
  computed: {
    datePickerEndDateOptions: function() {
      if ( this.filter.startDate ) {
        return {
          minDate: this.filter.startDate,
          format: 'YYYY-MM-DD',
          useCurrent: false,
        }
      } else {
        return {
          format: 'YYYY-MM-DD',
          useCurrent: false,
        }
      }
    },
  },
  methods: {
    customLabel( treatment ) {
      return treatment.name
    },
    getTreatment( key ) {
      let _ = this
      _.axios.get('/me/treatments'
        + '?page=' + _.treatmentCurrentPage
        + '&limit=' + _.treatmentLimit
        + '&sortBy=' +  _.treatmentSortby 
        + '&search=' + (key ? key : '')
      )
      .then( resp => {
        _.treatments = resp.data.data.rows
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    },
    submitFilter() {
      this.$root.$emit('setFilter', this.filter)
      this.isFiltered = true
    },
    resetFilter() {
      this.filter = {
        startDate: null,
        endDate: null,
        treatmentId: null
      }
      this.selectedTreatment = null
      this.isFiltered = false
      this.$root.$emit('setFilter', this.filter)
    }
  },
  mounted() {
    this.getTreatment()
  },
  watch: {
    'filter.startDate': function() {
      this.filter.endDate = null
    },
    selectedTreatment: function() {
      if ( this.selectedTreatment ) {
        this.filter.treatmentId = this.selectedTreatment.id
      } else {
        this.filter.treatmentId = null
      }
    }
  }
}
</script>