<template>
  <div>
    <section v-if="state == 'detail'">
      <router-link to="/sales-order" class="color-gray-100 d-inline-block text-semiBold mb-4">
        <span class="icon-ico-back me-2"></span> Detail Order
      </router-link>
      <div class="row ">
        <div class="col-lg-7 mb-4">
          <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3 mb-3">
            <div class="d-flex align-items-center">
              <div class="bullet" :class="'bg-' + boStatusColor(model.status)"></div>
              <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + boStatusColor(model.status)">{{ boStatusText(model.status) }}</div>
            </div>
            <div class="text-xs text-italic color-gray-80" v-if="model.status == 2">Terimakasih telah melayani kustomer dengan pelayanan terbaik</div>
            <div v-if="model.status == -4">
              <div class="text-xs text-italic color-gray-80">{{ model.changeInvoiceStatus }}</div>
              <hr class="dashed">
              <div class="text-xs color-gray-80">Alasan</div>
              <div class="text-xs text-medium">{{ model.changeInvoiceReason ? model.changeInvoiceReason : '-' }}</div>
            </div>
          </div>
          <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3">
            <div class="d-flex justify-content-between mb-3">
              <div class="col-auto">
                <div class="text-xs color-gray-40">Nomor Order</div>
              </div>
              <div class="col-8">
                <div class="text-xs text-medium text-right d-block">{{ model.noOrder }}</div>
              </div>
            </div>
            <hr class="dashed">
            <div class="d-flex justify-content-between mb-3">
              <div class="col-auto">
                <div class="text-xs color-gray-40">Nama Kustomer</div>
              </div>
              <!-- <div class="col-8" v-if="model.booking && model.booking.bookingProfile">
                <div class="text-xs text-medium text-right d-block">{{ model.booking.bookingProfile.name }} 
                  <router-link :to="'/sales-order/' + $route.params.id + '/medical-record/' + model.booking.bookingProfile.linkedAccountId">Lihat Medical Record</router-link>
                </div>
              </div> -->
              <div class="col-8">
                <div class="text-xs text-medium text-right d-block">{{ model.customer ? model.customer.profile.fullName : '-' }} 
                  <router-link :to="'/sales-order/' + $route.params.id + '/medical-record/' + model.customer.id">Lihat Medical Record</router-link>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between mb-3">
              <div class="col-auto">
                <div class="text-xs color-gray-40">Treatment</div>
              </div>
              <div class="col-8">
                <div class="text-xs text-medium text-right d-block" v-if="model.treatments.length > 0">{{ model.treatments.map( x => x.treatment.name ).join(", ") }}</div>
                <div class="text-xs text-medium text-right d-block" v-else>-</div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="text-xs color-gray-40">Staff</div>
              <div class="text-xs text-medium">{{ model.staff ? model.staff.profile.fullName : '-' }}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="text-xs color-gray-40">Keluhan</div>
              <div class="text-xs text-medium">{{ model.description ? model.description : '-' }}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3" v-if="!model.booking || model.status == 2">
              <div class="text-xs color-gray-40">Harga</div>
              <div class="text-xs text-medium">{{ totalPriceTreatment | currency }}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center mb-3">
              <div class="text-xs color-gray-40">Durasi</div>
              <div class="text-xs text-medium">{{ convertMinute(totalDuration) }}</div>
            </div>

            <div class="d-flex justify-content-between align-items-center ">
              <div class="text-xs color-gray-40">Waktu Order</div>
              <div class="text-xs text-medium">{{ model.startDate | moment('DD MMM YYYY, HH:mm')}} WIB</div>
            </div>

            <section v-if="model.products.length > 0">
              <hr class="dashed">
              <div class="d-flex justify-content-between align-items-center ">
                <div class="text-xs color-gray-40">{{ model.products.length | numFormat }} Produk <a class="ms-3 open-product">Lihat Detail</a></div>
                <div class="text-xs text-medium">{{ totalPriceProduct | currency }}</div>
              </div>
            </section>

            <section v-if="model.status != 2 && selectedProducts.length > 0">
              <hr class="dashed">
              <div class="d-flex justify-content-between align-items-center ">
                <div class="text-xs color-gray-40">{{ selectedProducts.length | numFormat }} Produk <a class="ms-3 open-product">Lihat Detail</a></div>
                <div class="text-xs text-medium">{{ totalPriceProduct | currency }}</div>
              </div>
            </section>

            <section v-if="!model.booking">
              <hr class="dashed">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Metode Pembayaran</div>
                <div class="text-xs text-medium">{{ paymentMethodText( model.invoice.paymentMethod )}}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.invoice.voucherCode">
                <div class="text-xs color-gray-40">Voucher Diskon ({{model.invoice.voucherCode}})</div>
                <div class="text-xs text-medium">{{ model.invoice.discount * -1 | currency }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Total Pembayaran</div>
                <div class="text-xs text-semiBold color-red-100">{{ model.invoice.totalPayment | currency }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Uang Bayar</div>
                <div class="text-xs text-semiBold color-red-100">{{ model.invoice.cashpay | currency }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center ">
                <div class="text-xs color-gray-40">Kembalian</div>
                <div class="text-xs text-semiBold color-red-100">{{ model.invoice.cashpay - model.invoice.totalPayment | currency }}</div>
              </div>
            </section>
            <section v-else-if="model.booking && model.status == 2">
              <hr class="dashed">
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Metode Pembayaran</div>
                <div class="text-xs text-medium">{{ paymentMethodText( model.invoice.paymentMethod )}}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-3" v-if="model.invoice.voucherCode">
                <div class="text-xs color-gray-40">Voucher Diskon ({{model.invoice.voucherCode}})</div>
                <div class="text-xs text-medium">{{ model.invoice.discount * -1 | currency }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Total Pembayaran</div>
                <div class="text-xs text-semiBold color-red-100">{{ model.invoice.totalPayment | currency }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Uang Bayar</div>
                <div class="text-xs text-semiBold color-red-100">{{ model.invoice.cashpay | currency }}</div>
              </div>
              <div class="d-flex justify-content-between align-items-center ">
                <div class="text-xs color-gray-40">Kembalian</div>
                <div class="text-xs text-semiBold color-red-100">{{ model.invoice.cashpay - model.invoice.totalPayment | currency }}</div>
              </div>
            </section>
          </div>
        </div>
        <!--End Form-->

        <div class="col-lg-5" v-if="model.status == 1">
          <div class="text-sm text-semiBold mb-2">Catatan</div>
          <ValidationObserver ref="observer">
            <div class="col-sm-12 mb-3">
              <label class="text-xs color-gray-40 mb-2">Rekomendasi</label>
              <div class="position-relative">
                <div class="">
                  <input type="text" class="form-control" name="" placeholder="Tulis rekomendasi produk / treatment" v-model="form.recomendation">
                </div>
              </div>
            </div>
            <div class="col-sm-12 mb-3">
              <label class="text-xs color-gray-40 mb-2">Catatan</label>
              <div class="position-relative">
                <div class="">
                  <input type="text" class="form-control" name="" placeholder="Tulis catatan" v-model="form.notes">
                </div>
              </div>
            </div>
          </ValidationObserver>
          <div class="row gx-3" v-if="model.booking">
            <div class="col">
              <button class="btn btn-primary w-100" type="button" @click="tempSelectedProducts = JSON.parse(JSON.stringify(selectedProducts)), products = [], getProduct(1), state = 'product'">Tambah Produk</button>
            </div>
            <div class="col">
              <button class="btn btn-border-primary w-100" type="button" @click="state = 'payment', previousState = 'detail'">Pembayaran</button>
            </div>
          </div>
          <button v-else class="btn btn-primary w-100" type="button" @click="finishOrder()">Selesai</button>
        </div>
        <div class="col-lg-5" v-if="model.status == 2">
          <div class="text-sm text-semiBold mb-2">Catatan</div>
            <div class="rounded-12 bg-white border-1 border-solid border-color-gray p-3 mb-3">
              <div class="mb-3">
                <div class="text-xs color-gray-60 mb-2">Rekomendasi</div>
                <div class="text-sm color-gray-100 text-medium">{{ model.recomendation ? model.recomendation : '-' }}</div>
              </div>
              <div class="mb-0">
                <div class="text-xs color-gray-60 mb-2">Catatan</div>
                <div class="text-sm color-gray-100 text-medium">{{ model.notes ? model.notes : '-' }}</div>
              </div>
            </div>
            <button type="button" class="btn btn-border-primary w-100 change-invoice" v-if="!model.isInvoiceChanged">Ubah Invoice</button>
        </div>
        <div class="col-lg-5" v-if="model.status == -4">
          <div class="text-sm text-semiBold mb-2">Catatan</div>
          <div class="rounded-12 bg-white border-1 border-solid border-color-gray p-3 mb-3">
            <div class="mb-3">
              <div class="text-xs color-gray-60 mb-2">Rekomendasi</div>
              <div class="text-sm color-gray-100 text-medium">{{ model.recomendation ? model.recomendation : '-' }}</div>
            </div>
            <div class="mb-0">
              <div class="text-xs color-gray-60 mb-2">Catatan</div>
              <div class="text-sm color-gray-100 text-medium">{{ model.notes ? model.notes : '-' }}</div>
            </div>
          </div>
          <router-link :to="'/sales-order/' + $route.params.id + '/new-invoice'" v-if="model.changeInvoiceStatus == 'Perubahan invoice disetujui'">
            <button type="button" class="btn btn-primary w-100">Buat Invoice</button>
          </router-link>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-else-if="state == 'product'">
      <a @click="state = 'detail'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Tambah Produk</a>

      <div class="row gx-xl-5">
        <div class="col-lg-7 mb-4">
          <div class="position-relative mb-3">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchProduct" placeholder="Cari Produk" v-model="productSearch">
            </div>
          </div>

          <div class="d-grid grid-product gap-3">
            <div v-for="product in products" :key="product.id"
              class="bg-white border-1 border-solid border-color-gray rounded-12 p-3">
              <div class="img-product center-content mb-3">
                <img :src="product.product && product.product.detailItemImage.length > 0 ? product.product.detailItemImage[0] : '/img/product-1.png'" class="img-fluid">
              </div>
              <div class="text-xs text-medium text-center px-2 mb-2">{{ product.productName }}</div>
              <div class="text-xs color-gray-60 text-center mb-2">Stok {{ product.stock | numFormat }}</div>
              <div class="text-xs color-red-100 text-semiBold text-center mb-2">{{ product.price | currency }}</div>
              <div class="d-flex justify-content-center align-items-center">
                <button @click="product.qty -= 1, addOrSubProduct(product, 'sub')" :disabled="product.qty == 0"
                  class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                </button>
                <input type="number" class="form-control input-number" :min="0" :max="product.stock" :disabled="product.stock == 0"
                  v-model="product.qty" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                <button @click="product.qty += 1, addOrSubProduct(product, 'add')" :disabled="product.qty >= product.stock"
                  class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                </button>
              </div>
            </div>
            <!--End List Product-->
          </div>
          <p class="text-center text-sm" v-if="products.length == 0">Tidak ada produk</p>
          <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
            <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
          </p>
        </div>
        <!--End List Treatment-->

        <div class="col-lg-5">
          <div class="accordion mb-3">
            <div class="accordion-item  border-1 border-solid border-color-gray">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button justify-content-between text-sm color-gray-100 collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ tempSelectedProducts.length }} Terpilih</span>
                      <span class="color-red-100 text-semiBold me-2 text-xs">{{ totalPriceTempProduct | currency }}</span>
                    </div>
                  </div>
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne">
                <div class="d-flex justify-content-between align-items-center py-3 px-4"
                  v-for="product in tempSelectedProducts" :key="product.id">
                  <div>
                    <div class="text-xs text-medium mb-1">{{ product.name }}</div>
                    <div class="text-xs text-semiBold color-red-100">{{ product.price | currency }}</div>
                  </div>
                  <div class="col-auto">
                    <div class="d-flex justify-content-center align-items-center">
                      <button @click="product.qty -= 1, addOrSubSelectedProduct(product, 'sub')" :disabled="product.qty == 0"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                      </button>
                      <input type="number" class="form-control input-number" :min="0" :max="product.stock"
                        v-model="product.qty" @change="handleCustomQtySelectedProduct($event, product)" @keypress="numbersOnly">
                      <button @click="product.qty += 1, addOrSubSelectedProduct(product, 'add')" :disabled="product.qty >= product.stock"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button @click="selectedProducts = JSON.parse(JSON.stringify(tempSelectedProducts)), state = 'detail', previousState = 'product'" class="btn btn-primary w-100">Selanjutnya</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-else-if="state == 'payment'">
      <a @click="state = previousState, selectedVoucher = null, voucherCode = null" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Pembayaran</a>

      <div class="row ">
        <div class="col-lg-7 mb-4">
          <div class=" mb-3">
            <ValidationObserver ref="voucher">
              <ValidationProvider name="No. Debit" v-slot="{ errors }" ref="voucherCode">
                <label class="text-xs color-gray-40 mb-2">Voucher</label>
                <div class="d-flex">
                  <div class="col">
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" id="" placeholder="Masukkan Kode Voucher" value="" v-model="voucherCode" :disabled="selectedVoucher">
                    </div>
                  </div>
                  <div class="col-auto ps-2">
                    <button class="btn btn-primary" type="button" @click="claimVoucher()" :disabled="!voucherCode" v-if="!selectedVoucher">Klaim</button>
                    <button class="btn btn-primary" type="button" @click="selectedVoucher = null, voucherCode = null" v-else>Batal</button>
                  </div>
                </div>
                <small class="text-success text-sm text-xs" v-if="selectedVoucher">Voucher berhasil diklaim</small>
                <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
              </ValidationProvider>
            </ValidationObserver>
          </div>

          <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3">
            <section v-if="model.treatments.length > 0">
              <div class="d-flex justify-content-between mb-3">
                <div class="col-auto">
                  <div class="text-xs color-gray-40">Treatment</div>
                </div>
                <div class="col-8">
                  <div class="text-xs text-medium text-right d-block" v-if="model.treatments.length > 0">{{ model.treatments.map( x => x.treatment.name ).join(", ") }}</div>
                  <div class="text-xs text-medium text-right d-block" v-else>-</div>
                </div>
              </div>

              <div class="d-flex justify-content-between align-items-center mb-3">
                <div class="text-xs color-gray-40">Harga</div>
                <div class="text-xs text-medium">{{ totalPriceTreatment | currency }}</div>
              </div>
            </section>
            <div class="d-flex justify-content-between align-items-center">
              <div class="text-xs color-gray-40">Down Payment 30%</div>
              <div class="text-xs text-medium">{{ model.booking.downpayment * -1 | currency }}</div>
            </div>
            <hr class="dashed">

            <section v-if="selectedProducts.length > 0">
              <div class="d-flex justify-content-between align-items-center ">
                <div class="text-xs color-gray-40">{{selectedProducts.length | numFormat}} Produk <a class="ms-3 open-product">Lihat Produk</a></div>
                <div class="text-xs text-medium">{{ totalPriceProduct | currency }}</div>
              </div>
              <hr class="dashed">
            </section>

            <section v-if="selectedVoucher">
              <div class="d-flex justify-content-between align-items-center ">
                <div class="text-xs color-gray-40">Voucher Diskon ({{ voucherCode }})</div>
                <div class="text-xs text-medium">{{ totalDiscount * -1 | currency }}</div>
              </div>
              <hr class="dashed">
            </section>

            <div class="d-flex justify-content-between align-items-center">
              <div class="text-xs color-gray-40">Sisa pembayaran</div>
              <div class="text-xs text-medium color-red-100">{{ totalPriceFinal | currency }}</div>
            </div>
          </div>
        </div>

        <div class="col-lg-5">
          <ValidationObserver ref="observer">
            <form @submit.prevent="finishOrderFullPayment()">
              <div class="text-sm color-gray-100 text-semiBold mb-3">Pilih Metode Pembayaran</div>
              <div class="choose-user mb-3">
                <input type="radio" class="hidden" id="tunai" name="payment" v-model="paymentMethod" :value="1">
                <label for="tunai" class="cursor-pointer"><span class="ms-4">Tunai</span> </label>
              </div>
              <div class="choose-user mb-3">
                <input type="radio" class="hidden" id="debit" name="payment" v-model="paymentMethod" :value="2">
                <label for="debit" class="cursor-pointer"><span class="ms-4">Bank Transfer</span> </label>
              </div>
              <div class="choose-user mb-3">
                <input type="radio" class="hidden" id="ewallet" name="payment" v-model="paymentMethod" :value="3">
                <label for="ewallet" class="cursor-pointer"><span class="ms-4">E-Wallet</span> </label>
              </div>

              <hr class="dashed">

              <div class="mb-3">
                <ValidationProvider name="No. Telepon" v-slot="{ errors }" rules="required|min:8|max:15" ref="phoneNumber" v-if="paymentMethod == 3">
                  <div class="form-group">
                    <label class="text-xs color-gray-40 mb-2">No. Telepon</label>
                    <input type="text" name="" id="" class="form-control" v-model="phoneNumber" @keypress="numbersOnly">
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </div>
                </ValidationProvider>
                <ValidationProvider :name="paymentMethod == 2 ? 'Nominal Transfer' : 'Jumlah pembayaran'" v-slot="{ errors }" :rules="{required:true, min_value: totalPriceFinal}" ref="name">
                  <label class="text-xs color-gray-40 mb-2">{{ paymentMethod == 2 ? 'Nominal Transfer' : 'Jumlah Pembayaran' }}</label>
                  <div class="position-relative">
                    <div class="icn-left ">
                      <vue-numeric @keypress.native="numbersOnly" v-model="cashValue" class="form-control" currency="" separator="." placeholder="0" :disabled="paymentMethod != 1"></vue-numeric>
                    </div>
                  </div>
                  <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                </ValidationProvider>

                <section v-if="paymentMethod == 1">
                  <label class="text-xs color-gray-40 mb-2">Kembalian</label>
                  <div class="position-relative">
                    <div class="icn-left ">
                      <vue-numeric @keypress.native="numbersOnly" v-model="cashChange" class="form-control" currency="" separator="." placeholder="0" disabled></vue-numeric>
                    </div>
                  </div>
                </section>
              </div>

              <button type="submit" class="btn btn-primary w-100">Selesai</button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </section>

    <div class="overlay-popup ">
      <div id="open-product" class="box-sm hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Detail Produk</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup" v-if="model.products.length > 0">
          <div class="d-flex justify-content-between align-items-center py-3 " v-for="product in model.products" :key="product.id">
            <div>
              <div class="text-xs text-medium mb-1">{{ product.product ? product.product.name : '-' }}</div>
              <div class="text-xs text-semiBold color-red-100">{{ product.unitPrice | currency }}</div>
            </div>
            <div class="col-auto">
              <div class="text-sm">{{ product.qty }}</div>
            </div>
          </div>
        </div>
        <div class="content-popup" v-else-if="selectedProducts.length > 0">
          <div class="d-flex justify-content-between align-items-center py-3"
            v-for="product in selectedProducts" :key="product.id">
            <div>
              <div class="text-xs text-medium mb-1">{{ product.productName }}</div>
              <div class="text-xs text-semiBold color-red-100">{{ product.price | currency }}</div>
            </div>
            <div class="col-auto">
              <div class="text-sm">{{ product.qty }}</div>
            </div>
          </div>
        </div>
      </div>

      <div id="change-invoice" class="box-xs hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Ubah Invoice</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <label class="text-xs color-gray-40 mb-2">Alasan</label>
        <div class="position-relative mb-3">
          <textarea class="form-control" rows="4" placeholder="Tulis alasan" v-model="invoice.changeInvoiceReason"></textarea>
        </div>
        <div class="row gx-3">
          <div class="col-6">
            <button class="btn btn-primary w-100 close-btn" type="button">Kembali</button>
          </div>
          <div class="col-6">
            <button class="btn btn-border-primary w-100" :disabled="!invoice.changeInvoiceReason" @click="changeInvoice()">Kirim</button>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer :value="tempSelectedProducts" :expand-depth="5"></json-viewer>
    <json-viewer :value="selectedProducts" :expand-depth="5"></json-viewer> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: "detail",
      previousState: "",
      model: {
        customer: {
          profile: {}
        },
        treatments: [],
        staff: {
          profile: {}
        },
        invoice: {},
        products: [],
        booking: null
      },
      form: {
        id: this.$route.params.id,
        recomendation: null,
        notes: null
      },
      invoice: {
        id: null,
        changeInvoiceReason: null
      },
      voucherCode: "",
      selectedVoucher: null,
      paymentMethod: 1,
      debitNumber: null,
      cashValue: "",
      phoneNumber: "",

      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',
      selectedProducts: [],

      tempSelectedProducts: [],
    }
  },
  computed: {
    totalDuration: function() {
      let duration = 0
      this.model.treatments.forEach(elm => {
        duration += parseInt(elm.durationHours ? elm.durationHours : 0) * 60 + parseInt(elm.durationMinutes ? elm.durationMinutes : 0)
      });
      return duration
    },
    totalPriceTreatment: function() {
      let price = 0
      this.model.treatments.forEach(elm => {
        price += parseInt(elm.price)
      });
      return price
    },
    totalPriceTempProduct: function() {
      let price = 0

      if ( this.tempSelectedProducts.length > 0 ) {
        this.tempSelectedProducts.forEach(elm => {
          price += parseInt(elm.price) * elm.qty
        });
      } else {
        this.model.products.forEach(elm => {
          price += parseInt(elm.unitPrice) * elm.qty
        });
      }

      
      return price
    },
    totalPriceProduct: function() {
      let price = 0

      if ( this.selectedProducts.length > 0 ) {
        this.selectedProducts.forEach(elm => {
          price += parseInt(elm.price) * elm.qty
        });
      } else {
        this.model.products.forEach(elm => {
          price += parseInt(elm.unitPrice) * elm.qty
        });
      }

      
      return price
    },
    totalPrice: function() {
      return this.totalPriceTreatment + this.totalPriceProduct
    },
    totalDiscount: function() {
      return this.selectedVoucher ? this.selectedVoucher.discount : 0
    },
    totalPriceFinal: function() {
      return this.totalPriceTreatment + this.totalPriceProduct - this.totalDiscount - (this.model.booking ? this.model.booking.downpayment : 0)
    },
    cashChange: function() {
      if ( !this.cashValue ) return ""
      return this.cashValue - this.totalPriceFinal
    },
    finishRequest: function() {
      let _ = this

      let payload = {
        id: parseInt(_.form.id),
        recomendation: _.form.recomendation,
        notes: _.form.notes,
      }

      if ( _.model.booking ) {
        delete payload.id

        payload.salesId = parseInt(_.form.id),
        payload.products = this.selectedProducts.map( function(x) {
          return {
            accuProductId: x.accuId,
            qty: x.qty,
            unitPrice: parseInt(x.price),
          }
        })
        payload.paymentMethod = _.paymentMethod
        payload.voucherCode = _.voucherCode
        payload.cashPay = _.totalPriceFinal + ''
        payload.debitNumber = _.paymentMethod == 2 ? _.debitNumber : null
        payload.phoneNumber = _.paymentMethod == 3 ? _.phoneNumber : null
        payload.discount = _.totalDiscount
      }

      return payload
    },
    requestClaim: function() {
      let _ = this
      return {
        voucherCode: _.voucherCode,
        items: _.selectedProducts.map( function(x) {
          const product = { ...x }
          delete product.qty
          return product
        }),
        cashPay: _.totalPriceFinal + (_.model.booking ? parseInt(_.model.booking.downpayment) : 0) + (_.selectedVoucher ? _.selectedVoucher.discount : 0)
      }
    }
  },
  methods: {
    showBooking() {
      let _ = this

      _.axios.get('/sales-order/' + _.$route.params.id)
        .then( resp => {
          _.model = resp.data.data

          if (_.model.booking) {
            _.getProduct(1)
          }

          _.invoice.id = _.model.invoice.id
        })
        .catch( err => {
          console.log(err)
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    convertMinute( minute ) {
      return parseInt(minute / 60) + ' Jam ' + (minute % 60) + ' Menit'
    },
    async finishOrder() {
      let _ = this

      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      _.axios.put('/sales-order/finish-order', _.finishRequest)
        .then(() => {
          _.showBooking()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    async finishOrderFullPayment() {
      let _ = this

      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.selectedVoucher ) {
        const check = await _.checkVoucher()
        if ( !check ) return;
      }

      _.axios.post('/sales-order/booking-treatment/full-payment', _.finishRequest)
        .then(() => {
          _.showBooking()
          _.state = 'detail'
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    changeInvoice() {
      let _ = this

      _.axios.put('/sales-order/change-invoice', _.invoice)
        .then(() => {
          $('.close-popup').click()
          _.showBooking()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    claimVoucher() {
      let _ = this 

      _.$refs['voucherCode'].applyResult({
        errors: [],
        valid: false,
        failedRules: {}
      });

      _.axios.post('/promotions/claim-voucher', _.requestClaim)
        .then( resp => {
          _.selectedVoucher = resp.data.data
        })
        .catch( err => {
          _.$refs['voucherCode'].applyResult({
            errors: [err.response.data.message],
            valid: false,
            failedRules: {}
          });
        })
    },
    async checkVoucher() {
      let _ = this

      const res = await _.axios.post('/promotions/claim-voucher', _.requestClaim)
        .then(() => {
          return true
        })
        .catch( err => {
          _.selectedVoucher = null
          _.voucherCode = null

          if (err.response.data) {
            _.$refs['voucherCode'].applyResult({
              errors: [err.response.data.message],
              valid: false,
              failedRules: {}
            });
          } else {
            _.$refs['voucherCode'].applyResult({
              errors: [err.response.message],
              valid: false,
              failedRules: {}
            });
          }

          this.$notify({
            type: 'warn',
            title: 'Voucher Gagal Diklaim',
            text: 'Order tidak memenuhi syarat & ketentuan voucher'
          });

          return false
        })

      return res
    },
    getProduct( page ) {
      let _ = this
      _.axios.get('/inventory/product'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.productCurrentPage = data.currentPage
            _.productTotalPage = data.totalPages

            data.rows.forEach(elm => {
              let qty = 0
              let searchProduct = _.tempSelectedProducts.map( z => z.id ).indexOf( elm.id )
              if ( searchProduct != -1 ) {
                qty = _.tempSelectedProducts[searchProduct].qty
              }
              _.products.push({ ...elm, qty: qty })
            });
          }
        })
        .catch( err => {
          console.log(err)
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchProduct() {
      let _ = this
      _.products = []
      _.getProduct(1)
    },
    addOrSubProduct( product, type ) {
      let _ = this

      let searchSelectedProduct = _.tempSelectedProducts.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchSelectedProduct != -1 ) {
          _.tempSelectedProducts[searchSelectedProduct].qty += 1
        } else {
          _.tempSelectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.tempSelectedProducts[searchSelectedProduct].qty == 1 ) {
          _.tempSelectedProducts.splice(searchSelectedProduct, 1)
        } else {
          _.tempSelectedProducts[searchSelectedProduct].qty -= 1
        }
      }
    },
    addOrSubSelectedProduct( product, type ) {
      let _ = this

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchProduct != -1 ) {
          _.products[searchProduct].qty += 1
        } else {
          _.products.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.products[searchProduct].qty == 1 ) {
          let searchSelectedProduct = _.tempSelectedProducts.map( x => x.id ).indexOf( product.id )
          _.tempSelectedProducts.splice(searchSelectedProduct, 1)
          $('.close-popup').click()
        }
        _.products[searchProduct].qty -= 1
      }
    },
    handleCustomQty( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.stock < event.target.value ) {
        product.qty = parseInt( product.stock )
      }

      let searchSelectedProduct = _.tempSelectedProducts.map( x => x.id ).indexOf( product.id )
      if ( searchSelectedProduct != -1 ) {
        _.tempSelectedProducts[searchSelectedProduct].qty = product.qty
        if (product.qty == 0) {
          _.tempSelectedProducts.splice(searchSelectedProduct, 1)
        }
      } else {
        if (product.qty > 0) {
          _.tempSelectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      }
    },
    handleCustomQtySelectedProduct( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.stock < event.target.value ) {
        product.qty = parseInt( product.stock )
      }

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )
      if ( searchProduct != -1 ) {
        _.products[searchProduct].qty = product.qty
        if (product.qty == 0) {
          let searchSelectedProduct = _.tempSelectedProducts.map( x => x.id ).indexOf( product.id )
          _.tempSelectedProducts.splice(searchSelectedProduct, 1)
          $('.close-popup').click()
        }
      }
    },
  },
  mounted() {
    this.showBooking()
  },
  watch: {
    paymentMethod: function() {
      if ( this.paymentMethod != 1 ) { //TUNAI
        this.cashValue = this.totalPriceFinal
      } else {
        this.cashValue = 0
      }
    }
  }
}
</script>