<template>
  <div>
    <h6 class="text-semiBold color-gray-100 text-center mb-4">Kata Sandi Baru</h6>
    <p class="text-sm color-gray-100">Tuliskan kata sandi baru.</p>
    <ValidationObserver ref="observer">
        <form @submit.prevent="resetPassword()">
          <ValidationProvider name="Password baru" v-slot="{ errors }" rules="required|strong_password" ref="newPassword">
            <div class="form-group auth mb-3">
              <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Password Baru</label>
              <div class="position-relative">
                <input type="password" id="password" class="form-control" placeholder="" name="" v-model="model.newPassword" @keydown.space.prevent>
                <span toggle="#password" class="btn-eye"></span>
              </div>
              <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <ValidationProvider name="Konfirmasi password baru" v-slot="{ errors }" rules="required|strong_password|confirmed:Password baru">
            <div class="form-group auth mb-5">
              <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Konfirmasi Password Baru</label>
              <div class="position-relative">
                <input type="password" id="password2" class="form-control" placeholder="" name="" v-model="model.confPassword" @keydown.space.prevent>
                <span toggle="#password2" class="btn-eye"></span>
              </div>
              <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
            </div>
          </ValidationProvider>
          <button type="submit" class="btn btn-primary w-100">Simpan</button>
        </form>
    </ValidationObserver>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        newPassword: null,
        confPassword: null,
        resetPasswordToken: null
      }
    }
  },
  methods: {
    async resetPassword() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      const data = {
        newPassword: _.model.newPassword,
        resetPasswordToken: _.model.resetPasswordToken,
      }
      
      _.axios.post('/auth/reset-password', data)
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.push('/login')
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              this.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          }
        })
    }
  },
  mounted() {
    let _ = this
    _.model.resetPasswordToken = _.$route.query.resetPasswordToken
  },
}
</script>