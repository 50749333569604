<template>
  <div>
    <a @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span> Semua Promo
    </a>

    <div class="row gx-3" v-if="promos.length > 0">
      <div class="col-xl-4 col-lg-6 mb-3" v-for="promo in promos" :key="promo.id">
        <a @click="promoDetail = promo" class="bg-white btn-staff d-block rounded-12 p-3">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="text-medium color-gray-100 text-sm">{{ promo.name }}</div>
            <div class="status bg-lightTiffany color-tiffany text-sm text-medium" v-if="promo.createdByDetail && promo.createdByDetail.roleId === 1">Pusat</div>
            <div class="status bg-lightTosca color-darkTosca text-sm text-medium" v-else>Owner</div>
          </div>
          <hr class="dashed">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="col-6">
              <div class="text-sm color-gray-80">Durasi</div>
            </div>
            <div class="col-6">
              <div class="text-sm color-gray-100 text-right text-medium text-hidden">{{ promo.startDate | moment('DD/MM/YYYY') }} - {{ promo.endDate | moment('DD/MM/YYYY') }}
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" v-if="promo.promoType == 1">
            <div class="col-6">
              <div class="text-sm color-gray-80">Nominal Diskon (Rp)</div>
            </div>
            <div class="col-6">
              <div class="text-sm color-gray-100 text-right text-medium text-hidden">{{ promo.discountPrice | currency }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" v-if="promo.promoType == 2 || promo.promoType == 3">
            <div class="col-6">
              <div class="text-sm color-gray-80">Persentase (%)</div>
            </div>
            <div class="col-6">
              <div class="text-sm color-gray-100 text-right text-medium text-hidden">{{ promo.discountPercentage | numFormat }}%</div>
            </div>
          </div>
          <hr class="dashed">
          <div class="text-sm color-red-100 text-hidden">Diskon {{ promo.type }}</div>
        </a>
      </div>
    </div>
    <p class="text-center text-sm" v-if="promos.length == 0">Tidak ada promo</p>
    <p class="text-center text-sm" v-if="promos.length > 0 && promoCurrentPage < promoTotalPage">
      <a class="color-gray-100" @click="getPromo( promoCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
    </p>
    
    <div class="overlay-popup ">
      <div id="staff" class="box-sm hidden bg-white rounded-12 p-3" v-if="promoDetail">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Detail Diskon</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup mb-4">
          <div class="text-sm text-medium mb-1">{{ promoDetail.name }}</div>
          <div class="text-sm color-red-100 mb-2">Diskon {{ promoDetail.type }}</div>
          <hr class="dashed">
          <!-- <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Target</div>
            <div class="text-sm color-gray-100 text-medium">Pelanggan Baru</div>
          </div> -->
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Kode Voucher</div>
            <div class="text-sm color-red-100 text-medium">{{ promoDetail.voucherCode}}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Min. Pembelian (Rp)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.minimumPayment | currency }}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" v-if="promoDetail.promoType == 1">
            <div class="text-sm color-gray-80">Nominal Diskon (Rp)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.discountPrice | currency }}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" v-else>
            <div class="text-sm color-gray-80">Persentase (%)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.discountPercentage | numFormat }}%</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Kuota (pengguna)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.quota | numFormat }}</div>
          </div>
        </div>
        <button class="btn btn-primary w-100" @click="copy(promoDetail.voucherCode)">Salin Kode Promo</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      promos: [],
      promoCurrentPage: 1,
      promoTotalPage: 1,
      promoLimit: 10,
      promoSortby: 'id.desc',
      promoSearch: '',
      promoStatus: null,
      promoDetail: null
    }
  },
  methods: {
    getPromo(page) {
      let _ = this
      
      _.axios.get('/me/promotions'
          + '?page=' + page
          + '&limit=' + _.promoLimit
          + '&sortBy=' + _.promoSortby
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.promoCurrentPage = data.currentPage
            _.promoTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.promos.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.getPromo(1)
  },
}
</script>