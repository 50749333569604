<template>
  <div>
    <h6 class="text-semiBold color-gray-100 text-center mb-4">Kode Verifikasi</h6>
    <a @click="$router.back()" class="text-lg d-block text-bold mb-3"><strong><span
          class="icon-ico-back"></span></strong></a>
    <p class="text-sm color-gray-100 mb-4">Masukkan 4 digit kode yang kami kirimkan ke email <span
        class="color-red-100">{{ model.email }}</span>
    </p>

    <ValidationObserver ref="observer">
      <form @submit.prevent="verify()">
        <div id="form-code" class="d-flex justify-content-center mb-4">
          <v-otp-input
            ref="otpInput"
            input-classes="otp"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-change="model.otp = null"
            @on-complete="handleOnComplete"
          />
        </div>
        <button type="submit" class="btn btn-primary w-100 mb-3" :disabled="!model.otp">Selanjutnya</button>
      </form>
    </ValidationObserver>

    <p class="text-center mb-0 mt-1"> <small class="text-danger text-sm text-xs">{{errorMsg}}</small></p>

    <a @click="forgotPassword()" class="text-sm text-semiBold text-center d-block">Kirim Ulang Kode</a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        kfsId: null,
        email: null,
        otpToken: null,
        otp: null
      },
      errorMsg: null
    }
  },
  methods: {
    handleOnComplete(value) {
      this.model.otp = value
      this.verify()
    },
    tabChange(val) {
      let ele = document.querySelectorAll('input');
      if (ele[val - 1].value != '') {
        if (!ele[val]) return
        ele[val].focus()
      } 
      else if (ele[val - 1].value == '') {
        if (!ele[val - 2]) return
        ele[val - 2].focus()
      }
    },
    async verify() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      const data = {
        otpToken: _.model.otpToken,
        otp: _.model.otp
      }

      _.axios.post('/auth/verify-otp', data)
        .then( resp => {
          if ( resp.data.success ) {
            _.$router.push('/reset-password?resetPasswordToken=' + resp.data.data.resetPasswordToken)
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              this.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.errorMsg = err.response.data.message
          }
        })
    },
    forgotPassword() {
      let _ = this

      this.$refs.otpInput.clearInput();
      
      _.axios.post('/auth/forgot-password', { email: _.model.email, roleId: 3 })
        .then( resp => {
          if ( resp.data.success ) {
            _.model.otpToken = resp.data.data.otpToken

            _.$notify({
              type: 'success',
              title: 'Berhasil mengirim ulang kode'
            })
          }
        })
        .catch( err => {
          _.errorMsg = err.response.data.message
        })
    }
  },
  mounted() {
    let _ = this
    _.model.otpToken = _.$route.query.otpToken
    _.model.email = _.$route.query.email
    _.model.kfsId = _.$route.query.kfsId
  },
}
</script>