<template>
  <div>
    <a href="#" @click="$router.back()" class="color-gray-100 d-inline-block text-semiBold mb-4">
      <span class="icon-ico-back me-2"></span> Data Pelanggan
    </a>

    <nav>
      <div class="nav nav-tabs flex-nowrap mb-4" id="information-tab" role="tablist">
        <button @click="state = 'Agenda'" class="nav-link active" id="bantuan-tab" data-bs-toggle="tab" data-bs-target="#bantuan"
          type="button" role="tab" aria-controls="bantuan" aria-selected="true">Agenda</button>
        <button @click="state = 'Produk'" class="nav-link" id="tentang-kfs-tab" data-bs-toggle="tab" data-bs-target="#tentang-kfs"
          type="button" role="tab" aria-controls="tentang-kfs" aria-selected="false">Produk</button>
        <button @click="state = 'Invoice'" class="nav-link" id="syarat-tab" data-bs-toggle="tab" data-bs-target="#syarat" type="button"
          role="tab" aria-controls="syarat" aria-selected="false">Invoice</button>
        <button @click="state = 'MedicalRecord'" class="nav-link text-hidden" id="medical-tab" data-bs-toggle="tab" data-bs-target="#medical"
          type="button" role="tab" aria-controls="medical" aria-selected="false">Medical Record</button>
        <button @click="state = 'DetailProfile'" class="nav-link text-hidden" id="dp-tab" data-bs-toggle="tab" data-bs-target="#dp" type="button"
          role="tab" aria-controls="dp" aria-selected="false">Detail Profile</button>
        <button @click="state = 'OldTransaction'" class="nav-link text-hidden" id="old-tab" data-bs-toggle="tab" data-bs-target="#old" type="button"
          role="tab" aria-controls="old" aria-selected="false">Old Transaction</button>
      </div>
    </nav>
    <div class="tab-content">
      <div class="tab-pane fade" :class="state == 'Agenda' ? 'show active' : ''" 
        id="bantuan" role="tabpanel" aria-labelledby="bantuan-tab" tabindex="0">
        <Agenda v-if="state == 'Agenda'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'Produk' ? 'show active' : ''" 
        id="tentang-kfs" role="tabpanel" aria-labelledby="tentang-kfs-tab" tabindex="0">
        <Produk v-if="state == 'Produk'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'Invoice' ? 'show active' : ''" 
        id="syarat" role="tabpanel" aria-labelledby="syarat-tab" tabindex="0">
        <Invoice v-if="state == 'Invoice'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'MedicalRecord' ? 'show active' : ''" 
        id="medical" role="tabpanel" aria-labelledby="medical-tab" tabindex="0">
        <MedicalRecord v-if="state == 'MedicalRecord'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'DetailProfile' ? 'show active' : ''" 
        id="dp" role="tabpanel" aria-labelledby="dp-tab" tabindex="0">
        <DetailProfile v-if="state == 'DetailProfile'"/>
      </div>
      <div class="tab-pane fade" :class="state == 'OldTransaction' ? 'show active' : ''" 
        id="old" role="tabpanel" aria-labelledby="old-tab" tabindex="0">
        <OldTransaction v-if="state == 'OldTransaction'"/>
      </div>
    </div>
  </div>
</template>
<script>
import Agenda from './Agenda.vue'
import Produk from './Produk.vue'
import Invoice from './Invoice.vue'
import MedicalRecord from './MedicalRecord.vue'
import DetailProfile from './DetailProfile.vue'
import OldTransaction from './OldTransaction.vue'

export default {
	components: {
    Agenda,
    Produk,
    Invoice,
    MedicalRecord,
    DetailProfile,
    OldTransaction,
	},
  data() {
    return {
      state: 'Agenda'
    }
  },
}
</script>