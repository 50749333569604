<template>
  <div>
    <section v-if="state == 'sales-order'">
      <a @click="$router.back()" v-if="!isFromMember" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Sales Order</a>
      <a @click="state = 'member'" v-else class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Sales Order</a>

      <div class="row gx-xl-5">
        <div class="col-lg-7 mb-4">
          <div class="position-relative mb-3" v-show="childState == 'treatment'">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchTreatment" placeholder="Cari Treatment" v-model="treatmentSearch">
            </div>
          </div>
          <div class="position-relative mb-3" v-show="childState == 'produk'">
            <div class="form-search">
              <input type="text" class="form-control" v-debounce:300.lock="searchProduct" placeholder="Cari Produk" v-model="productSearch">
            </div>
          </div>

          <ul class="nav nav-tabs border-0 mb-3" id="tabTop" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="childState == 'treatment'? 'active' : ''" id="all" data-bs-toggle="tab" data-bs-target="#all-pane" type="button"
                role="tab" aria-controls="all-pane" aria-selected="true" @click="childState = 'treatment'">Treatment</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" :class="childState == 'produk'? 'active' : ''" id="waiting-tab" data-bs-toggle="tab" data-bs-target="#waiting-tab-pane"
                type="button" role="tab" aria-controls="waiting-tab-pane" aria-selected="false" @click="childState = 'produk'">Produk</button>
            </li>
          </ul>

          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="childState == 'treatment'? 'show active' : ''" id="all-pane" role="tabpanel" aria-labelledby="all-tab" tabindex="0">
              <div v-for="treatment in treatments" :key="treatment.id"
                class="position-relative border-1 border-solid border-color-gray rounded-12 bg-white deco-treatment me-2 me-lg-0 p-3 mb-3">
                <div class="d-flex">
                  <div class="me-3">
                    <img :src="treatment.collectionImage && treatment.collectionImage.length > 0 ? treatment.collectionImage[0] : '/img/user-1.jpg'" class="img-48">
                  </div>
                  <div class="col">
                    <div class="text-medium mb-1">{{ treatment.name }}</div>
                    <ul class="item-info mb-0">
                      <li><span>{{ treatment.durationHours ? treatment.durationHours : 0 }} Jam {{ treatment.durationMinutes ? treatment.durationMinutes : 0 }} Mnt</span></li>
                      <li>
                        <div class="color-red-100 text-semiBold">{{ treatment.price | currency }}</div>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="choose-treatment">
                  <input type="checkbox" name="treatment" class="hidden" :id="'treatment-' + treatment.id" 
                    :checked="selectedTreatments.map( x => x.id ).indexOf(treatment.id) != -1"
                    @change="selectUnselectTreatment($event, treatment)">
                  <label :for="'treatment-' + treatment.id" class="cursor-pointer"></label>
                </div>
              </div>
              <p class="text-center text-sm" v-if="treatments.length == 0">Tidak ada treatment</p>
              <p class="text-center text-sm" v-if="treatments.length > 0 && treatmentCurrentPage < treatmentTotalPage">
                <a class="color-gray-100" @click="getTreatment( treatmentCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
            <div class="tab-pane fade" :class="childState == 'produk'? 'show active' : ''" id="waiting-tab-pane" role="tabpanel" aria-labelledby="waiting-tab" tabindex="0">
              <div class="d-grid grid-product gap-3">
                <div v-for="product in products" :key="product.id"
                  class="bg-white border-1 border-solid border-color-gray rounded-12 p-3">
                  <div class="img-product center-content mb-3">
                    <img :src="product.product && product.product.detailItemImage.length > 0 ? product.product.detailItemImage[0] : '/img/product-1.png'" class="img-fluid">
                  </div>
                  <div class="text-xs text-medium text-center px-2 mb-2">{{ product.productName }}</div>
                  <div class="text-xs color-gray-60 text-center mb-2">Stok {{ product.stock | numFormat }}</div>
                  <div class="text-xs color-red-100 text-semiBold text-center mb-2">{{ product.price | currency }}</div>
                  <div class="d-flex justify-content-center align-items-center">
                    <button @click="product.qty -= 1, addOrSubProduct(product, 'sub')" :disabled="product.qty == 0"
                      class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                    </button>
                    <input type="number" class="form-control input-number" :min="0" :max="product.stock" :disabled="product.stock == 0"
                      v-model="product.qty" @change="handleCustomQty($event, product)" @keypress="numbersOnly">
                    <button @click="product.qty += 1, addOrSubProduct(product, 'add')" :disabled="product.qty >= product.stock"
                      class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                    </button>
                  </div>
                </div>
                <!--End List Product-->
              </div>
              <p class="text-center text-sm" v-if="products.length == 0">Tidak ada produk</p>
              <p class="text-center text-sm" v-if="products.length > 0 && productCurrentPage < productTotalPage">
                <a class="color-gray-100" @click="getProduct( productCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
              </p>
            </div>
          </div>
        </div>
        <!--End List Treatment-->

        <div class="col-lg-5">
          <div class="accordion mb-3">
            <div class="accordion-item  border-1 border-solid border-color-gray">
              <div class="accordion-header" id="headingOne">
                <button class="accordion-button justify-content-between text-sm color-gray-100 collapsed" type="button"
                  data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                  aria-controls="collapseOne">
                  <div class="col">
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ totalSelected }} Terpilih</span>
                      <span class="color-red-100 text-semiBold me-2 text-xs">{{ totalPrice | currency }}</span>
                    </div>
                  </div>
                </button>
              </div>
              <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne">
                <div class="d-flex justify-content-between align-items-center p-4"
                  v-for="treatment in selectedTreatments" :key="treatment.id">
                  <div class="text-sm">{{ treatment.name }}</div>
                  <div class="col-auto">
                    <div class="d-flex">
                      <div class="text-xs color-red-100 text-semiBold">{{ treatment.price | currency }}</div>
                      <button class="btn p-0 ms-2" type="button" @click="unselectTreatment(treatment)">
                        <span class="icon-ico-minus-circle color-gray-60"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center py-3 px-4"
                  v-for="product in selectedProducts" :key="product.id">
                  <div>
                    <div class="text-xs text-medium mb-1">{{ product.productName }}</div>
                    <div class="text-xs text-semiBold color-red-100">{{ product.price | currency }}</div>
                  </div>
                  <div class="col-auto">
                    <div class="d-flex justify-content-center align-items-center">
                      <button @click="product.qty -= 1, addOrSubSelectedProduct(product, 'sub')" :disabled="product.qty == 0"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-minus-circle text-lg"></span>
                      </button>
                      <input type="number" class="form-control input-number" :min="0" :max="product.stock"
                        v-model="product.qty" @change="handleCustomQtySelectedProduct($event, product)" @keypress="numbersOnly">
                      <button @click="product.qty += 1, addOrSubSelectedProduct(product, 'add')" :disabled="product.qty >= product.stock"
                        class="btn px-1 py-0 color-red-100"><span class="icon-ico-plus-circle text-lg"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button @click="state = 'member'" class="btn btn-primary w-100" :disabled="totalSelected == 0">Selanjutnya</button>
        </div>
        <!--End Right Side Bar-->
      </div>
    </section>
    <section v-else-if="state == 'member'">
      <a @click="state = 'sales-order'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Sales Order</a>
      
      <ValidationObserver ref="observer">
        <form @submit.prevent="goToPayment()">
          <div class="row gx-xl-5">
            <div class="col-lg-7 mb-4">
              <div class="row gx-3">
                <div class="col-sm-6 mb-3">
                  <div class="choose-user">
                    <input type="radio" class="hidden" id="member" name="member" v-model="memberType" :value="'member'">
                    <label for="member" class="cursor-pointer"><span class="ms-4">Member</span> </label>
                  </div>
                </div>
                <div class="col-sm-6 mb-3">
                  <div class="choose-user">
                    <input type="radio" class="hidden" id="guest" name="member" v-model="memberType" :value="'new'">
                    <label for="guest" class="cursor-pointer"><span class="ms-4">Kustomer Baru</span> </label>
                  </div>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Nama" v-slot="{ errors }" rules="required" ref="name">
                    <label class="text-xs color-gray-40 mb-2">Nama </label>
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.name">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Nomor handphone" v-slot="{ errors }" rules="required|min:8|max:15" ref="phone">
                    <label class="text-xs color-gray-40 mb-2">Nomor Handphone </label>
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.phone" @keypress="numbersOnly($event)">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
                    <label class="text-xs color-gray-40 mb-2">Email </label>
                    <div class="position-relative">
                      <input type="email" class="form-control" name="" placeholder="" v-model="model.customer.email">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Alamat" v-slot="{ errors }" rules="required" ref="address">
                    <label class="text-xs color-gray-40 mb-2">Alamat </label>
                    <div class="position-relative">
                      <input type="text" class="form-control" name="" placeholder="" v-model="model.customer.address">
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Jenis kelamin" v-slot="{ errors }" rules="required" ref="gender">
                    <label class="text-xs color-gray-40 mb-2">Jenis Kelamin </label>
                    <div class="position-relative">
                      <div class="icn-chevron">
                        <select class="form-select" v-model="model.customer.gender">
                          <option :value="null">Pilih Jenis Kelamin</option>
                          <option value="laki">Laki-laki</option>
                          <option value="perempuan">Perempuan</option>
                        </select>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3" v-if="memberType == 'new'">
                  <ValidationProvider name="Tanggal lahir" v-slot="{ errors }" rules="required" ref="bod">
                    <label class="text-xs color-gray-40 mb-2">Tanggal Lahir</label>
                    <div class="position-relative">
                      <div class=" icn-calendar">
                        <date-picker v-model="model.customer.bod" :config="datePickerDobOptions" placeholder="Pilih Tanggal"></date-picker>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-xl-12 mb-3" v-else>
                  <ValidationProvider name="Nama kustomer" v-slot="{ errors }" rules="required" ref="name">
                    <label class="text-xs color-gray-40 mb-2">Nama Kustomer</label>
                    <multiselect 
                        track-by="id" 
                        placeholder="Pilih Kustomer"
                        v-model="selectedCustomer"
                        @search-change="getCustomer"
                        :options="customers"
                        :custom-label="customLabel"
                        :multiple="false" 
                        :clear-on-select="false" 
                        :close-on-select="true"
                        :internal-search="false">
                        <span slot="noResult">Maaf, Customer tidak ditemukan</span>
                    </multiselect>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
              </div>
              <div class="text-sm text-semiBold mb-3">Waktu Order</div>
              <div class="row gx-3">
                <div class="col-sm-6 mb-3">
                  <ValidationProvider name="Tanggal" v-slot="{ errors }" rules="required" ref="startDate">
                    <label class="text-xs color-gray-40 mb-2">Tanggal</label>
                    <div class="position-relative">
                      <div class=" icn-calendar">
                        <date-picker v-model="model.bookingDate" :config="datePickerOptions" placeholder="Pilih Tanggal" disabled></date-picker>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 mb-3">
                  <ValidationProvider name="Jam" v-slot="{ errors }" rules="required" ref="startDate">
                    <label class="text-xs color-gray-40 mb-2">Jam</label>
                    <div class="position-relative">
                      <div class=" icn-clock">
                        <date-picker v-model="model.bookingTime" :config="timePickerOptions" placeholder="Pilih Jam" disabled></date-picker>
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 mb-3">
                  <ValidationProvider name="Staff" v-slot="{ errors }" rules="required" ref="staffAccountId">
                    <label class="text-xs color-gray-40 mb-2">Staff</label>
                    <div class="position-relative">
                      <div class="">
                        <input type="text" class="form-control" name="" id="choose-staff" placeholder="Pilih staff" v-model="selectedStaff.profile.fullName">
                      </div>
                    </div>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12 mb-3">
                  <label class="text-xs color-gray-40 mb-2">Keluhan</label>
                  <div class="position-relative">
                    <div class="">
                      <input type="text" class="form-control" name="" placeholder="Tulis keluhan pasien" v-model="model.description">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--End Form-->

            <div class="col-lg-5">
              <div class="text-sm text-semiBold mb-2">Rincian Order</div>
              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <section v-if="selectedTreatments.length > 0">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="col-auto">
                      <div class="text-xs color-gray-40">Treatment</div>
                    </div>
                    <div class="col-8">
                      <div class="text-xs text-medium text-right d-block" v-if="selectedTreatments.length > 0">{{ selectedTreatments.map( x => x.name ).join(", ") }}</div>
                      <div class="text-xs text-medium text-right d-block" v-else>-</div>
                    </div>

                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="text-xs color-gray-40">Harga</div>
                    <div class="text-xs text-medium">{{ totalPriceTreatment | currency }}</div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <div class="text-xs color-gray-40">Durasi</div>
                    <div class="text-xs text-medium" v-if="selectedTreatments.length > 0">{{ convertMinute(totalDuration) }}</div>
                    <div class="text-xs text-medium" v-else>-</div>
                  </div>
                </section>
                <div class="d-flex justify-content-between align-items-center ">
                  <div class="text-xs color-gray-40">Waktu Order</div>
                  <div class="text-xs text-medium" v-if="model.bookingDate && model.bookingTime">{{ ($moment(model.bookingDate).format('YYYY-MM-DD') + ' ' + $moment(model.bookingTime).format('HH:mm:ss')) | moment('DD MMM YYYY, HH:mm')}} WIB</div>
                </div>

                <section v-if="selectedProducts.length > 0">
                  <hr class="dashed">
                  <div class="d-flex justify-content-between align-items-center ">
                    <div class="text-xs color-gray-40">{{selectedProducts.length | numFormat}} Produk <a class="ms-3 open-product">Lihat Produk</a></div>
                    <div class="text-xs text-medium">{{ totalPriceProduct | currency }}</div>
                  </div>
                </section>
              </div>
              
              <div class="bg-white rounded-12 p-3 border-1 border-solid border-color-gray mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-xs color-gray-40">Total Bayar</div>
                  <div class="text-xs text-semiBold color-red-100">{{ totalPrice | currency }}</div>
                </div>
              </div>

              <!-- <button class="btn btn-primary w-100 btn-pay" type="button" v-if="!invalid">Pilih Metode Pembayaran</button>
              <button class="btn btn-primary w-100" type="submit" v-else>Pilih Metode Pembayaran</button> -->
              <div class="row gx-3">
                <div class="col-6">
                  <button @click="state = 'sales-order', childState = 'produk', isFromMember = true" class="btn btn-primary w-100" type="button">Tambah Produk</button>
                </div>
                <div class="col-6">
                  <button class="btn btn-border-primary w-100" type="submit">Selanjutnya</button>
                </div>
              </div>
            </div>
            <!--End Right Side Bar-->
          </div>
        </form>
      </ValidationObserver>
    </section>
    <section v-else-if="state == 'payment'">
      <a @click="state = 'member'" class="color-gray-100 d-inline-block text-semiBold mb-4"><span
          class="icon-ico-back me-2"></span>
        Pembayaran</a>

          <div class="row ">
            <div class="col-lg-7 mb-4">
              <div class=" mb-3">
                <ValidationObserver ref="voucher">
                  <ValidationProvider name="No. Debit" v-slot="{ errors }" ref="voucherCode">
                    <label class="text-xs color-gray-40 mb-2">Voucher</label>
                    <div class="d-flex">
                      <div class="col">
                        <div class="position-relative">
                          <input type="text" class="form-control" name="" id="" placeholder="Masukkan Kode Voucher" value="" v-model="model.voucherCode" :disabled="selectedVoucher">
                        </div>
                      </div>
                      <div class="col-auto ps-2">
                        <button class="btn btn-primary" type="button" @click="claimVoucher()" :disabled="!model.voucherCode" v-if="!selectedVoucher">Klaim</button>
                        <button class="btn btn-primary" type="button" @click="selectedVoucher = null" v-else>Batal</button>
                      </div>
                    </div>
                    <small class="text-success text-sm text-xs" v-if="selectedVoucher">Voucher berhasil diklaim</small>
                    <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                  </ValidationProvider>
                </ValidationObserver>
              </div>
              <!--End Form Voucher-->

              <div class="bg-white rounded-12 border-1 border-solid border-color-gray p-3">
                <section v-if="selectedTreatments.length > 0">
                  <div class="d-flex justify-content-between mb-3">
                    <div class="col-auto">
                      <div class="text-xs color-gray-40">Treatment</div>
                    </div>
                    <div class="col-8">
                      <div class="text-xs text-medium text-right d-block" v-if="selectedTreatments.length > 0">{{ selectedTreatments.map( x => x.name ).join(", ") }}</div>
                      <div class="text-xs text-medium text-right d-block" v-else>-</div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="text-xs color-gray-40">Harga</div>
                    <div class="text-xs text-medium">{{ totalPriceTreatment | currency }}</div>
                  </div>
                  <hr class="dashed">
                </section>

                <section v-if="selectedProducts.length > 0">
                  <div class="d-flex justify-content-between align-items-center ">
                    <div class="text-xs color-gray-40">{{selectedProducts.length | numFormat}} Produk <a class="ms-3 open-product">Lihat Produk</a></div>
                    <div class="text-xs text-medium">{{ totalPriceProduct | currency }}</div>
                  </div>
                  <hr class="dashed">
                </section>

                <section v-if="selectedVoucher">
                  <div class="d-flex justify-content-between align-items-center ">
                    <div class="text-xs color-gray-40">Voucher Diskon ({{ model.voucherCode }})</div>
                    <div class="text-xs text-medium">{{ totalDiscount * -1 | currency }}</div>
                  </div>
                  <hr class="dashed">
                </section>

                <div class="d-flex justify-content-between align-items-center">
                  <div class="text-xs color-gray-40">Sisa Pembayaran</div>
                  <div class="text-xs text-medium">{{ totalPriceFinal | currency }}</div>
                </div>
              </div>
            </div>
            <!--End Info Payment-->

            <div class="col-lg-5">
              <ValidationObserver ref="observer">
                <form @submit.prevent="createSalesOrder()">
                  <div class="text-sm color-gray-100 text-semiBold mb-3">Pilih Metode Pembayaran</div>
                  <div class="choose-user mb-3">
                    <input type="radio" class="hidden" id="tunai" name="payment" v-model="model.paymentMethod" :value="1">
                    <label for="tunai" class="cursor-pointer"><span class="ms-4">Tunai</span> </label>
                  </div>
                  <div class="choose-user mb-3">
                    <input type="radio" class="hidden" id="debit" name="payment" v-model="model.paymentMethod" :value="2">
                    <label for="debit" class="cursor-pointer"><span class="ms-4">Bank Transfer</span> </label>
                  </div>
                  <div class="choose-user mb-3">
                    <input type="radio" class="hidden" id="ewallet" name="payment" v-model="model.paymentMethod" :value="3">
                    <label for="ewallet" class="cursor-pointer"><span class="ms-4">E-Wallet</span> </label>
                  </div>

                  <hr class="dashed">

                  <div class="mb-3">
                    <ValidationProvider name="Nomor handphone" v-slot="{ errors }" rules="required|min:8|max:15" ref="phoneNumber" v-if="model.paymentMethod == 3">
                      <div class="form-group">
                        <label class="text-xs color-gray-40 mb-2">Nomor Handphone </label>
                        <div class="position-relative">
                          <input type="text" class="form-control" name="" placeholder="" v-model="model.phoneNumber" @keypress="numbersOnly($event)">
                        </div>
                        <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                      </div>
                    </ValidationProvider>
                    <ValidationProvider :name="model.paymentMethod == 2 ? 'Nominal Transfer' : 'Jumlah pembayaran'" v-slot="{ errors }" :rules="{required:true, min_value: totalPriceFinal}" ref="name">
                      <label class="text-xs color-gray-40 mb-2">{{ model.paymentMethod == 2 ? 'Nominal Transfer' : 'Jumlah Pembayaran' }}</label>
                      <div class="position-relative">
                        <div class="icn-left ">
                          <vue-numeric @keypress.native="numbersOnly" v-model="cashValue" class="form-control" currency="" separator="." placeholder="0" :disabled="model.paymentMethod != 1"></vue-numeric>
                        </div>
                      </div>
                      <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
                    </ValidationProvider>

                    <section v-if="model.paymentMethod == 1">
                      <label class="text-xs color-gray-40 mb-2">Kembalian</label>
                      <div class="position-relative">
                        <div class="icn-left ">
                          <vue-numeric @keypress.native="numbersOnly" v-model="cashChange" class="form-control" currency="" separator="." placeholder="0" disabled></vue-numeric>
                        </div>
                      </div>
                    </section>
                  </div>

                  <button type="submit" class="btn btn-primary w-100">Simpan</button>
                </form>
              </ValidationObserver>
            </div>
            <!--End Right Side Bar-->
          </div>
    </section>

    <div class="overlay-popup ">
      <ChooseStaff :role="'terapis'"/>

      <div id="open-product" class="box-md hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Detail Produk</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup">
          <div class="d-flex justify-content-between align-items-center py-3"
            v-for="product in selectedProducts" :key="product.id">
            <div>
              <div class="text-xs text-medium mb-1">{{ product.productName }}</div>
              <div class="text-xs text-semiBold color-red-100">{{ product.price | currency }}</div>
            </div>
            <div class="col-auto">
              <div class="text-sm">{{ product.qty }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <json-viewer :value="requestClaim" :expand-depth="5"/> -->
  </div>
</template>
<script>
import ChooseStaff from '@/components/modals/ChooseStaff'
import ChoosePaymentMethod from '@/components/modals/ChoosePaymentMethod'

export default {
  components: {
    ChooseStaff,
    ChoosePaymentMethod
  },
  data() {
    return {
      state: 'sales-order',
      childState: 'treatment',

      datePickerDobOptions: {
        maxDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },
      
      datePickerOptions: {
        minDate: this.$moment().format('YYYY-MM-DD'),
        format: 'YYYY-MM-DD',
        useCurrent: true,
      },

      treatments: [],
      treatmentCurrentPage: 1,
      treatmentTotalPage: 1,
      treatmentLimit: 10,
      treatmentSortby: 'id.desc',
      treatmentSearch: '',

      products: [],
      productCurrentPage: 1,
      productTotalPage: 1,
      productLimit: 10,
      productSortby: 'id.desc',
      productSearch: '',

      memberType: 'member',
      model: {
        bookingDate: null,
        bookingTime: null,
        customer: {
          name: null,
          phone: null,
          email: null,
          bod: null,
          address: null,
          gender: null
        },
        paymentMethod: 1,
        debitNumber: null
      },

      customers: [],
      selectedCustomer: null,
      selectedStaff: {
        profile: {
          fullName: ""
        }
      },
      selectedTreatments: [],
      selectedProducts: [],
      cashValue: "",
      selectedVoucher: null,
      isFromMember: false
    }
  },
  computed: {
    timePickerOptions: function() {
      let minDate = this.$moment()

      if ( this.model.bookingDate == this.$moment().format('YYYY-MM-DD') ) {
        minDate = this.$moment()
      } else if (this.model.bookingDate) {
        minDate = this.$moment(this.model.bookingDate)
      }

      return {
        minDate: minDate,
        format: 'HH:mm',
        useCurrent: true,
      }
    },
    totalPriceTreatment: function() {
      let price = 0
      this.selectedTreatments.forEach(elm => {
        price += parseInt(elm.price)
      });
      return price
    },
    totalPriceProduct: function() {
      let price = 0
      this.selectedProducts.forEach(elm => {
        price += parseInt(elm.price) * elm.qty
      });
      return price
    },
    totalPrice: function() {
      return this.totalPriceTreatment + this.totalPriceProduct
    },
    totalDiscount: function() {
      return this.selectedVoucher ? this.selectedVoucher.discount : 0
    },
    totalPriceFinal: function() {
      return this.totalPriceTreatment + this.totalPriceProduct - this.totalDiscount
    },
    totalSelected: function() {
      return this.selectedTreatments.length + this.selectedProducts.length
    },
    totalDuration: function() {
      let duration = 0
      this.selectedTreatments.forEach(elm => {
        duration += parseInt(elm.durationHours ? elm.durationHours : 0) * 60 + parseInt(elm.durationMinutes ? elm.durationMinutes : 0)
      });
      return duration
    },
    request: function() {
      let _ = this
      const user = JSON.parse(localStorage.getItem('user'))

      return {
        kfsId: user.kfsId,
        staffAccountId: this.selectedStaff ? this.selectedStaff.profile.accountId : null,
        treatments: this.selectedTreatments.map( function(x) {
          return {
            treatmentId: x.id,
            qty: 1
          }
        }),
        products: this.selectedProducts.map( function(x) {
          return {
            accuProductId: x.accuId,
            qty: x.qty,
            unitPrice: parseInt(x.price),
          }
        }),
        ..._.model.customer,
        startDate: _.$moment(_.$moment(_.model.bookingDate).format('YYYY-MM-DD') + ' ' + _.$moment(_.model.bookingTime).format('HH:mm:ss')).utc().format(),
        description: _.model.description,
        paymentMethod: _.model.paymentMethod,
        voucherCode: _.model.voucherCode,
        cashPay: _.cashValue,
        debitNumber: _.model.paymentMethod == 2 ? _.model.debitNumber : null,
        phoneNumber: _.model.paymentMethod == 2 ? _.model.debitNumber : null,
        discount: _.totalDiscount,
      }
    },
    cashChange: function() {
      if ( !this.cashValue ) return ""
      return this.cashValue - this.totalPriceFinal
    },
    requestClaim: function() {
      let _ = this
      return {
        voucherCode: _.model.voucherCode,
        items: _.selectedProducts.map( function(x) {
          const product = { ...x }
          delete product.qty
          return product
        }),
        cashPay: _.totalPriceFinal
      }
    }
  },
  methods: {
    getTreatment( page ) {
      let _ = this
      _.axios.get('/me/treatments'
          + '?page=' + page
          + '&limit=' + _.treatmentLimit
          + '&sortBy=' + _.treatmentSortby
          + '&search=' + _.treatmentSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.treatmentCurrentPage = data.currentPage
            _.treatmentTotalPage = data.totalPages

            data.rows.forEach(elm => {
              _.treatments.push({ ...elm, selected: false})
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getProduct( page ) {
      let _ = this
      _.axios.get('/inventory/product'
          + '?page=' + page
          + '&limit=' + _.productLimit
          + '&sortBy=' + _.productSortby
          + '&search=' + _.productSearch
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            _.productCurrentPage = data.currentPage
            _.productTotalPage = data.totalPages

            data.rows.forEach(elm => {
              let qty = 0
              let searchProduct = _.selectedProducts.map( z => z.id ).indexOf( elm.id )
              if ( searchProduct != -1 ) {
                qty = _.selectedProducts[searchProduct].qty
              }
              _.products.push({ ...elm, qty: qty })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchTreatment() {
      this.treatments = []
      this.getTreatment(1)
    },
    searchProduct() {
      let _ = this
      _.products = []
      _.getProduct(1)
    },
    selectUnselectTreatment( event, treatment ) {
      if ( event.target.checked ) {
        this.selectedTreatments.push(treatment)
      } else {
        let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
        if ( searchSelectedTreatment != -1 ) {
          this.selectedTreatments.splice(searchSelectedTreatment, 1)
        }
      }
    },
    unselectTreatment( treatment ) {
      let searchSelectedTreatment = this.selectedTreatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.selectedTreatments.splice(searchSelectedTreatment, 1)
      }

      let searchTreatment = this.treatments.map(x => x.id).indexOf( treatment.id )
      if ( searchSelectedTreatment != -1 ) {
        this.treatments[searchTreatment].selected = false
      }
    },
    getCustomer( key ) {
      let _ = this
      _.axios.get('/customers'
        + '?page=1'
        + '&limit=10'
        + '&sortBy=id.desc'
        + '&search=' + (key ? key : '')
      )
      .then( resp => {
        _.customers = resp.data.data.rows
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    },
    customLabel( customer ) {
      return customer.profile.fullName + ' - ' + customer.email
    },
    convertMinute( minute ) {
      return parseInt(minute / 60) + ' Jam ' + (minute % 60) + ' Menit'
    },
    async createSalesOrder() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return

      if ( _.selectedVoucher ) {
        const check = await _.checkVoucher()
        if ( !check ) return;
      }

      _.axios.post('/sales-order', _.request)
        .then(() => {
          _.$router.push('/sales-order')
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    addOrSubProduct( product, type ) {
      let _ = this

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchSelectedProduct != -1 ) {
          _.selectedProducts[searchSelectedProduct].qty += 1
        } else {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.selectedProducts[searchSelectedProduct].qty == 1 ) {
          _.selectedProducts.splice(searchSelectedProduct, 1)
        } else {
          _.selectedProducts[searchSelectedProduct].qty -= 1
        }
      }
    },
    addOrSubSelectedProduct( product, type ) {
      let _ = this

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )

      if ( type == 'add' ) {
        if ( searchProduct != -1 ) {
          _.products[searchProduct].qty += 1
        } else {
          _.products.push(JSON.parse(JSON.stringify(product)))
        }
      } else if ( type == 'sub' ) {
        if ( _.products[searchProduct].qty == 1 ) {
          let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
          _.selectedProducts.splice(searchSelectedProduct, 1)
          $('.close-popup').click()
        }
        _.products[searchProduct].qty -= 1
      }
    },
    handleCustomQty( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.stock < event.target.value ) {
        product.qty = parseInt( product.stock )
      }

      let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
      if ( searchSelectedProduct != -1 ) {
        _.selectedProducts[searchSelectedProduct].qty = product.qty
        if (product.qty == 0) {
          _.selectedProducts.splice(searchSelectedProduct, 1)
        }
      } else {
        if (product.qty > 0) {
          _.selectedProducts.push(JSON.parse(JSON.stringify(product)))
        }
      }
    },
    handleCustomQtySelectedProduct( event, product ) {
      let _ = this
      product.qty = parseInt( event.target.value )
      if ( product.stock < event.target.value ) {
        product.qty = parseInt( product.stock )
      }

      let searchProduct = _.products.map( x => x.id ).indexOf( product.id )
      if ( searchProduct != -1 ) {
        _.products[searchProduct].qty = product.qty
        if (product.qty == 0) {
          let searchSelectedProduct = _.selectedProducts.map( x => x.id ).indexOf( product.id )
          _.selectedProducts.splice(searchSelectedProduct, 1)
          $('.close-popup').click()
        }
      }
    },
    async goToPayment() {
      let _ = this
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return
      _.state = 'payment'
    },
    claimVoucher() {
      let _ = this 

      _.$refs['voucherCode'].applyResult({
        errors: [],
        valid: false,
        failedRules: {}
      });

      _.axios.post('/promotions/claim-voucher', _.requestClaim)
        .then( resp => {
          _.selectedVoucher = resp.data.data
        })
        .catch( err => {
          _.$refs['voucherCode'].applyResult({
            errors: [err.response.data.message],
            valid: false,
            failedRules: {}
          });
        })
    },
    async checkVoucher() {
      let _ = this

      const res = await _.axios.post('/promotions/claim-voucher', _.requestClaim)
        .then(() => {
          return true
        })
        .catch( err => {
          _.selectedVoucher = null
          _.model.voucherCode = null

          if (err.response.data) {
            _.$refs['voucherCode'].applyResult({
              errors: [err.response.data.message],
              valid: false,
              failedRules: {}
            });
          } else {
            _.$refs['voucherCode'].applyResult({
              errors: [err.response.message],
              valid: false,
              failedRules: {}
            });
          }

          this.$notify({
            type: 'warn',
            title: 'Voucher Gagal Diklaim',
            text: 'Order tidak memenuhi syarat & ketentuan voucher'
          });

          return false
        })

      return res
    }
  },
  mounted() {
    let _ = this
    _.getTreatment(1)
    _.getProduct(1)
    _.getCustomer()

    _.model.bookingDate = _.$moment()
    _.model.bookingTime = _.$moment()

    _.$root.$on('setStaff', (data) => {
      _.selectedStaff = data
    })
  },
  watch: {
    selectedCustomer: function() {
      let _ = this
      if ( _.selectedCustomer ) {
        _.model.customer = {
          name: null,
          phone: null,
          email: _.selectedCustomer.email,
          bod: null,
          address: null,
          gender: _.selectedCustomer.profile.gender
        }
      } else {
        _.model.customer = {
          name: null,
          phone: null,
          email: null,
          bod: null,
          address: null,
          gender: null
        }
      }
    },
    memberType: function() {
      this.selectedCustomer = null
      this.$refs.observer.reset()
    },
    productLimit: function(){
      this.getProduct(1)
    },
    productSortby: function(){
      this.getProduct(1)
    },
    totalSelected: function(){
      if ( this.totalSelected == 0 ) {
        this.state = 'sales-order'
        this.childState = 'treatment'
      }
    },
    'model.paymentMethod': function() {
      if ( this.model.paymentMethod != 1 ) { //TUNAI
        this.cashValue = this.totalPriceFinal
      } else {
        this.cashValue = 0
      }
    },
    state: function() {
      if ( this.state == 'member' ) {
        this.isFromMember = false
      }
    }
  }
}
</script>