<template>
  <div>
    <div class="mt-sm-5 mt-4">
      <div class="bg-white shadow rounded-12 p-3">
        <div class="row justify-content-between">
          <div class="col-lg-7 col-xxl-8 mb-3">
            <div class="d-flex justify-content-between align-items-center">
              <div class="col">
                <div class="position-relative">
                  <div class="form-search">
                    <input type="text" class="form-control" name="" id=""
                      placeholder="Cari nama customer atau kata kunci" v-model="search">
                  </div>
                </div>
              </div>
              <div class="col-auto ms-3">
                <a href="#/" class="text-base filter text-medium" @click="tagSearch = null, getTag()">Filter</a>
              </div>
            </div>
          </div>

          <div class="col-12 col-lg-4 col-xxl-3 mb-3">
            <input type="file" class="hidden" id="upload" v-on:change="handleUpload" :accept="'.' + allowedExtension.join(', .').toLowerCase()">
            <div class="row gx-2">
              <div class="col-6">
                <div class="dropdown">
                  <button class="btn btn-secondary w-100" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <div class="d-flex justify-content-center align-items-center">
                      <span class="me-2">Lainnya</span> <span class="icon-ico-chev-down"></span>
                    </div>
                  </button>
                  <ul class="dropdown-menu border-0 shadow py-3">
                    <li><a class="dropdown-item text-sm py-1 color-gray-100" @click="exportCustomer()"><span
                          class="icon-ico-export color-red-100 me-2"></span> Export
                        Data</a></li>
                    <li>
                      <label for="upload" class="dropdown-item text-sm py-1 color-gray-100 cursor-pointer" id="xx">
                        <span class="icon-ico-import color-red-100 me-2"></span> Import Data
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-6">
                <router-link to="/customer/add" class="btn btn-primary w-100">Tambah</router-link>
              </div>
            </div>
          </div>
        </div>
        <Table :table="table"/>
      </div>
    </div>

    <div class="overlay-popup ">
      <div id="filter" class="box-md hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Filter</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup mb-3">
          <div class="position-relative mb-3">
            <div class="form-search">
              <input type="text" v-debounce:300.lock="getTag" class="form-control" name="" id="" placeholder="Cari Tag" v-model="tagSearch">
            </div>
          </div>
          <div style="max-height:300px;overflow:auto;" class="mb-3">
            <div class="position-relative" v-for="tag in tags" :key="tag.id">
              <input type="checkbox" :id="'tag-' + tag.id" name="tag" :value="tag.name" class="form-check-input" v-model="selectedTag">
              <label :for="'tag-' + tag.id" class="cursor-pointer mb-2" style="
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;">
                <span class="ms-2">{{ tag.name }}</span>
              </label>
            </div>
          </div>
          <div class="row gx-3">
            <div class="col-6">
              <button class="btn btn-border-primary w-100 close-btn" type="" @click="selectedTag = [], get(1)">Reset</button>
            </div>
            <div class="col-6">
              <button class="btn btn-primary w-100 close-btn" type="" @click="get(1)">Terapkan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'

export default {
	components: {
    Table
	},
  data() {
    return {
      allowedExtension: ['xlsx'],
      table: {
        options: {
          columns: [
            { name: 'checkbox', sortable: false, reference: null },
            { name: 'Nama', sortable: true, reference: 'profile.fullName' },
            { name: 'No. Telepon', sortable: true, reference: 'profile.phone' },
            { name: 'Email', sortable: false, reference: 'email' },
            { name: 'Member ID', sortable: false, reference: 'uniqueId' },
            { name: 'Kunjungan Terakhir', sortable: true, reference: 'updatedAt' },
            { name: 'Tags', sortable: false, reference: 'profile.since' },
            { name: 'Status', sortable: false, reference: 'status' },
          ],
          rows: [
            { reference: null, type: 'checkbox' },
            { reference: 'profile.fullName', type: 'link', url: '/customer/', url_reference: 'id' },
            { reference: 'profile.phone', type: 'text' },
            { reference: 'email', type: 'text' },
            { reference: 'uniqueId', type: 'text' },
            { reference: 'updatedAt', type: 'datetime' },
            { reference: 'profile.since', type: 'text' },
            { reference: 'status', type: 'badgeCustomer'},
          ],
        },
        data: []
      },
      limit: 10,
      sortby: 'id.desc',
      search: '',
      selected: [],
      tags: [],
      tagSearch: '',
      selectedTag: [],
      me: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    clickUpload() {
      $('#upload').click()
    },
    get( page ) {
      let _ = this
      _.axios.get('/customers'
        + '?page=' + page
        + '&limit=' + _.limit
        + '&sortBy=' + _.sortby
        + '&search=' + _.search
        + (_.selectedTag.length > 0 ? '&since=' + _.selectedTag.join(',') : '')
      )
      .then( resp => {
        _.table.data = resp.data.data
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    },
    exportCustomer() {
      let _ = this

      _.axios.get('/customers/export'
          + '?search=' + _.search 
          + (_.selected.length > 0 ? '&id=in:' + _.selected.join(',') : '')
        ,{responseType: 'arraybuffer'})
        .then(resp => {
            let blob        = new Blob([resp.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            let link        = document.createElement('a')
            link.href       = window.URL.createObjectURL(blob)
            link.download   = 'CUSTOMER-' + _.$moment().format('YYYYMMDDHHmmsss') + '.xlsx'
            link.click()
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    handleUpload(event){
      let _ = this

      let file = event.target.files[0]

      let formData = new FormData
      formData.append('file', file)
      formData.append('kfsId', _.me.kfsId)

      _.axios.post('/customers/import', formData)
        .then(() => {
          _.$notify({
            type: 'success',
            title: 'Import Customer Berhasil'
          });
          _.get(1)
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getTag() {
      let _ = this
      _.axios.get('/misc/regencies'
        + '?search=' + (_.tagSearch ? _.tagSearch : ''))
        .then( resp => {
          _.tags = resp.data.data.rows
        })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    }
  },
  mounted() {
    let _ = this
    _.get(1)
    _.getTag()

    _.$root.$on("changePage", (data) => {
      _.get(data)
    });
    _.$root.$on("changeLimit", (data) => {
      _.limit = data
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.sortby = 'id.desc'
      } else {
        _.sortby = data
      }
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
  },
  watch: {
    limit: function(){
      this.get(1)
    },
    sortby: function(){
      this.get(1)
    },
    search: function() {
      this.get(1)
    }
  }
}
</script>