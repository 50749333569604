<template>
  <div>
    <h6 class="text-semiBold color-gray-100 text-center">Selamat Datang</h6>
    <p class="text-center text-sm color-gray-60 mb-4">Masukkan alamat email yang sudah terdaftar</p>
    <ValidationObserver ref="observer">
      <form @submit.prevent="login()">
        <!-- <ValidationProvider name="KFS" v-slot="{ errors }" rules="required" ref="kfsId">
          <div class="form-group auth mb-4">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">KFS</label>
            <div class="position-relative">
              <select name="" id="" class="form-select" v-model="model.kfsId">
                <option :value="null">Pilih KFS</option>
                <option v-for="kfs in kfses" :key="kfs.id" :value="kfs.id">{{ kfs.name }}</option>
              </select>
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider> -->
        <ValidationProvider name="Email" v-slot="{ errors }" rules="required|email" ref="email">
          <div class="form-group auth mb-4">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Email</label>
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="" name="" value="" v-model="model.email">
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <ValidationProvider name="Password" v-slot="{ errors }" rules="required" ref="password">
          <div class="form-group auth mb-3">
            <label class="color-gray-100 text-sm text-xs text-semiBold mb-1">Password</label>
            <div class="position-relative">
              <input type="password" id="password" class="form-control" placeholder="" name="" value="" v-model="model.password">
              <span toggle="#password" class="btn-eye"></span>
            </div>
            <small class="text-danger text-sm text-xs" v-if="errors[0]">{{ errors[0] }}</small>
          </div>
        </ValidationProvider>
        <router-link to="/forgot-password" class="text-sm d-block text-right text-medium mb-4">Lupa Password?</router-link>
        <button type="submit" class="btn btn-primary w-100">Masuk</button>
      </form>
    </ValidationObserver>

    <p class="text-center mb-0 mt-1"> <small class="text-danger text-sm text-xs">{{errorMsg}}</small></p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        // kfsId: null,
        email: null,
        password: null,
        roleId: 3
      },
      // kfses: [],
      errorMsg: null
    }
  },
  methods: {
    async login() {
      let _ = this
      
      const isValid = await _.$refs.observer.validate()
      if ( !isValid ) return
      
      _.axios.post('/auth/login', { ..._.model, email: _.model.email.toLowerCase() })
        .then( resp => {
          if ( resp.data.data.roleId == 3 ) {
            _.$store.dispatch('login', resp.data.data)
            window.location = '/'
          } else {
            _.errorMsg = 'Maaf, Anda tidak memiliki akses'
          }
        })
        .catch( err => {
          if ( err.response.data.data ) {
            err.response.data.data.forEach(elm => {
              this.$refs[elm.context.key].applyResult({
                errors: [elm.message],
                valid: false,
                failedRules: {}
              });
            });
          } else {
            _.errorMsg = err.response.data.message
          }
        })
    },
    // getKfs() {
    //   let _ = this
    //   _.axios.get('/misc/kfs')
    //     .then( resp => {
    //       _.kfses = resp.data.data
    //     })
    //     .catch( err => {
    //       _.$notify({
    //         type: 'error',
    //         title: 'Terjadi Kesalahan ('+err.response.data.code+')',
    //         text: err.response.data.message
    //       });
    //     })
    // }
  },
  mounted() {
    // this.getKfs()
  },
}
</script>