<template>
  <div>
    <div class="bg-white shadow rounded-12 p-3">
      <div class="row gx-2 justify-content-between mb-3">
        <div class="col-12 col-sm-12">
          <div class="position-relative">
            <div class="form-search">
              <input type="text" class="form-control" name="" id="" placeholder="Cari kata kunci" value="" v-model="search">
            </div>
          </div>
        </div>
        <!-- <div class="col-5 col-lg-2 col-sm-4">
          <a class="btn btn-primary w-100 create-medical">Buat Baru</a>
        </div> -->
      </div>
      <Table :table="table"/>
    </div>

    <div class="overlay-popup">
      <div id="detail-medical" class="box-md hidden bg-white rounded-12 p-3" v-if="detail">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold text-base">Buat Medical Record</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="mb-3">
          <div class="text-xs color-gray-80 mb-1">Treatment</div>
          <div class="medical">
            <p class="text-medical">{{ detail.treatments }}</p>
          </div>
        </div>
        <div class="mb-3">
          <div class="text-xs color-gray-80 mb-1">Keluhan</div>
          <div class="medical">
            <p class="text-medical">{{ detail.description }}</p>
          </div>
        </div>
        <div class="mb-3">
          <div class="text-xs color-gray-80 mb-1">Rekomendasi </div>
          <div class="medical">
            <p class="text-medical">{{ detail.recomendation }}</p>
          </div>
        </div>
        <div class="mb-0">
          <div class="text-xs color-gray-80 mb-1">Catatan</div>
          <div class="medical">
            <p class="text-medical">{{ detail.notes }}</p>
          </div>
        </div>
      </div>

      <div id="create-medical" class="box-md hidden bg-white rounded-12 p-3">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold text-base">Buat Medical Record</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="mb-3">
          <div class="text-xs color-gray-80 mb-1">Treatment</div>
          <div class="medical">
            <input type="text" class="form-control" name="" placeholder="" v-model="form.treatments">
          </div>
        </div>
        <div class="mb-3">
          <div class="text-xs color-gray-80 mb-1">Keluhan</div>
          <div class="medical">
            <input type="text" class="form-control" name="" placeholder="" v-model="form.description">
          </div>
        </div>
        <div class="mb-3">
          <div class="text-xs color-gray-80 mb-1">Rekomendasi </div>
          <div class="medical">
            <input type="text" class="form-control" name="" placeholder="" v-model="form.recomendation">
          </div>
        </div>
        <div class="mb-0">
          <div class="text-xs color-gray-80 mb-1">Catatan</div>
          <div class="medical">
            <input type="text" class="form-control" name="" placeholder="" v-model="form.notes">
          </div>
        </div>
        <div class="center-content py-4">
          <button class="btn btn-primary w-50 close-btn" type="button">Simpan</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from '@/components/Table.vue'
export default {
	components: {
    Table
	},
  data() {
    return {
      table: {
        options: {
          columns: [
            { name: 'Tanggal Record', sortable: true, reference: 'startDate' },
            { name: 'Treatment', sortable: false, reference: 'treatments' },
            { name: 'Staff', sortable: false, reference: 'staff' },
            { name: 'Lokasi', sortable: false, reference: 'kfsName' },
            { name: 'Aksi', sortable: false, reference: 'medicalRecordDetail' },
          ],
          rows: [
            { reference: 'startDate', type: 'datetime' },
            { reference: 'treatments', type: 'text' },
            { reference: 'staff', type: 'text' },
            { reference: 'kfsName', type: 'text' },
            { reference: 'medicalRecordDetail', type: 'medicalRecordDetail' },
          ],
        },
        data: []
      },
      page: 1,
      limit: 10,
      sortby: 'id.desc',
      search: '',
      selected: [],
      detail: null,
      form: {
        treatments: null,
        description: null,
        recomendation: null,
        notes: null,
      }
    }
  },
  methods: {
    get() {
      let _ = this
      _.axios.get('/customers/' + _.$route.params.id + '/medical-histories'
        + '?page=' + _.page
        + '&limit=' + _.limit
        + '&sortBy=' + _.sortby
        + '&search=' + _.search
        + '&status=2'
      )
      .then( resp => {
        _.table.data = resp.data.data
      })
      .catch( err => {
        _.$notify({
          type: 'error',
          title: 'Terjadi Kesalahan ('+err.response.data.code+')',
          text: err.response.data.message
        });
      })
    }
  },
  mounted() {
    let _ = this
    _.get()

    _.$root.$on("changePage", (data) => {
      _.page = data
    });
    _.$root.$on("changeLimit", (data) => {
      _.limit = data
    });
    _.$root.$on("changeSortBy", (data) => {
      if ( data == '.' ) {
        _.sortby = 'id.desc'
      } else {
        _.sortby = data
      }
    });
    _.$root.$on("changeSelected", (data) => {
      _.selected = data
    });
    _.$root.$on("setMedicalRecordDetail", (data) => {
      _.detail = data
    });
  },
  beforeDestroy(){
    this.$root.$off("changePage");
    this.$root.$off("changeLimit");
    this.$root.$off("changeSortBy");
    this.$root.$off("changeSelected");
    this.$root.$off("setMedicalRecordDetail");
  },
  watch: {
    page: function(){
      this.get()
    },
    limit: function(){
      this.get()
    },
    sortby: function(){
      this.get()
    },
    search: function() {
      this.get()
    }
  }
}
</script>