<template>
  <div>
    <div id="staff" class="box-xs hidden bg-white rounded-12 p-3">
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="text-semiBold">Pilih Staff</div>
        <button class="btn close-popup p-0" type="button" @click="cancel()"><span class="icon-ico-close"></span></button>
      </div>
      <div class="content-popup mb-3">
        <div class="position-relative mb-2">
          <div class="form-search">
            <input type="text" v-debounce:300.lock="searchStaff" class="form-control" name="" id="" placeholder="Cari Staff" v-model="staffSearch">
          </div>
        </div>
        <div style="max-height:300px;overflow:auto;">
          <div class="position-relative" v-for="staff in staffs" :key="staff.id">
            <div class="choose-staff">
              <input type="radio" class="hidden" :id="'staff-' + staff.profile.accountId" name="staff" :value="staff.profile.accountId" v-model="tempSelectedStaffId">
              <label :for="'staff-' + staff.profile.accountId" class="cursor-pointer"><span class="ms-4">{{ staff.profile.fullName }}</span>
              </label>
            </div>
          </div>
        </div>

        <p class="text-center text-sm mt-2" v-if="staffs.length == 0">Tidak ada Staff</p>
        <p class="text-center text-sm mt-2" v-if="staffs.length > 0 && staffCurrentPage < staffTotalPage">
          <a class="color-gray-100" @click="getStaff( staffCurrentPage + 1 )">Muat lebih banyak <span class="icon-ico-chev-down"></span></a>
        </p>
      </div>
      <button class="btn btn-primary w-100 close-btn" type="button" @click="saveStaff()">Simpan</button>
    </div>
  </div>
</template>
<script>
export default {
  props: ['role'],
  data() {
    return {
      staffs: [],
      staffCurrentPage: 1,
      staffTotalPage: 1,
      staffLimit: 10,
      staffSortby: 'id.desc',
      staffSearch: '',
      selectedStaff: null,
      selectedStaffId: null,
      tempSelectedStaffId: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    getStaff( page ) {
      let _ = this

      let roleId = ""
      if ( _.role == 'kasir' ) {
        roleId = 3
      } else if ( _.role == 'terapis' ) {
        roleId = 4
      }

      _.axios.get('/sales-order/list-staff'
          + '?page=' + page
          + '&limit=' + _.staffLimit
          + '&sortBy=' + _.staffSortby
          + '&search=' + _.staffSearch
          + '&kfsId=' + _.user.kfsId
          + '&roleId=' + roleId
        )
        .then( resp => {
          const data = resp.data.data
          _.staffCurrentPage = data.currentPage
          _.staffTotalPage = data.totalPages

          data.rows.forEach(elm => {
            _.staffs.push({ ...elm})
          });
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    searchStaff() {
      this.staffs = []
      this.getStaff(1)
    },
    saveStaff() {
      let _ = this
      let searchStaff = _.staffs.map( x => x.profile.accountId ).indexOf( _.tempSelectedStaffId )
      if ( searchStaff != -1 ) {
        _.selectedStaff = _.staffs[searchStaff]
        _.selectedStaffId = _.selectedStaff.profile.accountId
        _.$root.$emit('setStaff', _.selectedStaff)
      }
      _.staffSearch = ''
      _.searchStaff()
    },
    cancel() {
      let _ = this
      if ( !_.selectedStaffId && _.selectedStaffId != _.tempSelectedStaffId ) {
        _.tempSelectedStaffId = null
      } else {
        _.tempSelectedStaffId = _.selectedStaffId
      }
      _.staffSearch = ''
      _.searchStaff()
    }
  },
  mounted() {
    this.getStaff(1)
  },
  watch: {
    staffLimit: function(){
      this.getStaff(1)
    },
    staffSortby: function(){
      this.getStaff(1)
    },
  }
}
</script>