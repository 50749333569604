import Vue from 'vue'

Vue.mixin({
  methods: {
    isAllowedExtension( filename, allowedExtension ) {
      var fileExtension = filename.split('.').pop().toLowerCase();
      var isValidFile = false;

      for(var index in allowedExtension) {
        if(fileExtension === allowedExtension[index]) {
          isValidFile = true; 
          break;
        }
      }

      return isValidFile;
    },
    isAllowedFileSize( size, maxFileSize ) {
      if (size > ((1024 * 1024) * maxFileSize)) {
        return false
      }

      return true
    },
    async upload( file, allowedExtension = null, maxFileSize = null, refs = null  ){
      let _ = this

      if (allowedExtension && !_.isAllowedExtension(file.name, allowedExtension)) {
        if ( refs ) {
          this.$refs[refs].reset();
          this.$refs[refs].applyResult({
            errors: ['Allowed file extensions are : *.' + allowedExtension.join(', *.')],
            valid: false,
            failedRules: {}
          });
        }
        return;
      }

      if (maxFileSize && !_.isAllowedFileSize(file.size, maxFileSize)) {
        if ( refs ) {
          this.$refs[refs].reset();
          this.$refs[refs].applyResult({
            errors: ['File is too large, allowed maximum size is '+ maxFileSize + ' Mb'],
            valid: false,
            failedRules: {}
          });
        }
        return;
      }

      let formData = new FormData()
      formData.append('file', file)

      const res = await _.axios.post('/misc/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' }})
        .then(resp => {
          return resp.data.data.file
        }).catch(err => {
          console.log(err.response)
        })

      return res
    },
    boStatusColor( status ) {
      let color = ''
      switch (status) {
        case -2:
          color = 'orange'
          break;
        case -1:
          color = 'blue'
          break;
        case 0:
          color = 'lightBlue'
          break;
        case 1:
          color = 'tosca'
          break;
        case 2:
          color = 'green'
          break;
        case -3:
          color = 'red-100'
          break;
        case -4:
          color = 'orange'
          break;
      
        default:
          break;
      }
      return color
    },
    boStatusText( status ) {
      let text = ''
      switch (status) {
        case -2:
          text = 'Menunggu Pembayaran'
          break;
        case -1:
          text = 'Menunggu Waktu Layanan'
          break;
        case 0:
          text = 'Menunggu Pemilihan Staff'
          break;
        case 1:
          text = 'Sedang Dilayani'
          break;
        case 2:
          text = 'Selesai'
          break;
        case -3:
          text = 'Dibatalkan'
          break;
        case -4:
          text = 'Ubah Invoice'
          break;
      
        default:
          break;
      }
      return text
    },
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault()
      } else {
          return true
      }
    },
    async copy( val ) {
      await navigator.clipboard.writeText(val);
      this.$notify({
        type: 'success',
        title: 'Berhasil disalin'
      })
    },
    paymentMethodText( paymentMethod ) {
      let text = ''
      switch (paymentMethod) {
        case 1:
          text = 'Tunai'
          break;
        case 2:
          text = 'Debit'
          break;
        case 3:
          text = 'E-Wallet'
          break;
      
        default:
          break;
      }
      return text
    }
  }
})