<template>
  <div>
    <div class="inner-home">
      <div class="text-base color-gray-100 text-semiBold mb-3">Layanan</div>
      <div class="row mb-4">
        <div class="col-lg-4 mb-3 mb-lg-0">
          <router-link to="/home/booking-treatment" class="bg-white d-block border-red rounded-10 p-4 shadow">
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-auto">
                <div class="img-layanan">
                  <img src="img/bt.svg" class="img-fluid">
                </div>
              </div>
              <div class="col">
                <div class="text-md text-medium color-gray-100 ms-3">Booking Treatment</div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="col-lg-4">
          <router-link to="/home/sales" class="bg-white d-block border-red rounded-10 p-4 shadow">
            <div class="d-flex align-items-center justify-content-center">
              <div class="col-auto">
                <div class="img-layanan">
                  <img src="img/so.svg" class="img-fluid">
                </div>
              </div>
              <div class="col">
                <div class="text-md text-medium color-gray-100 ms-3">Sales Order</div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="position-relative mt-4">
        <div class="text-base color-gray-100 text-semiBold mb-3">Promo Berjalan</div>
        <swiper class="swiper" :options="swiperOptionPromo">
          <swiper-slide v-for="promo in promos" :key="promo.id">
            <a @click="promoDetail = promo" class="bg-white btn-staff d-block rounded-12 p-3">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="text-medium color-gray-100 text-sm">{{ promo.name }}</div>
                <div class="status bg-lightTiffany color-tiffany text-sm text-medium" v-if="promo.createdByDetail && promo.createdByDetail.roleId === 1">Pusat</div>
                <div class="status bg-lightTosca color-darkTosca text-sm text-medium" v-else>Owner</div>
              </div>
              <hr class="dashed">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <div class="col-6">
                  <div class="text-sm color-gray-80">Durasi</div>
                </div>
                <div class="col-6">
                  <div class="text-sm color-gray-100 text-right text-medium text-hidden">{{ promo.startDate | moment('DD/MM/YYYY') }} - {{ promo.endDate | moment('DD/MM/YYYY') }}
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-2" v-if="promo.promoType == 1">
                <div class="col-6">
                  <div class="text-sm color-gray-80">Nominal Diskon (Rp)</div>
                </div>
                <div class="col-6">
                  <div class="text-sm color-gray-100 text-right text-medium text-hidden">{{ promo.discountPrice | currency }}</div>
                </div>
              </div>
              <div class="d-flex justify-content-between align-items-center mb-2" v-if="promo.promoType == 2 || promo.promoType == 3">
                <div class="col-6">
                  <div class="text-sm color-gray-80">Persentase (%)</div>
                </div>
                <div class="col-6">
                  <div class="text-sm color-gray-100 text-right text-medium text-hidden">{{ promo.discountPercentage | numFormat }}%</div>
                </div>
              </div>
              <hr class="dashed">
              <div class="text-sm color-red-100 text-hidden">Diskon {{ promo.type }}</div>
            </a>
          </swiper-slide>
        </swiper>

        <p class="text-center text-sm" v-if="promos.length == 0">Tidak ada promo</p>
        <div class="nav-slider">
          <div id="for-nav" class="d-flex justify-content-end align-items-center">
            <router-link to="/home/promo" class="text-sm text-medium">Lihat Semua</router-link>
            <div class="arrow-box" v-show="promos.length > 0">
              <div id="promo" class="swiper-button-next"></div>
              <div id="promo" class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative mt-4">
        <div class="text-base color-gray-100 text-semiBold mb-3">Sedang Dilayani</div>
        <swiper class="swiper" :options="swiperOptionSales">
          <swiper-slide v-for="order in saleses" :key="order.id">
            <router-link :to="'/booking-order/' + order.id" class="bg-white d-block rounded-12 p-3">
              <div class="d-flex align-items-center mb-3">
                <div class="bullet" :class="'bg-' + boStatusColor(order.status)"></div>
                <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + boStatusColor(order.status)">{{ boStatusText(order.status )}}</div>
              </div>
              <ul class="item-info">
                <li>{{ order.noOrder }}</li>
                <li>{{ order.treatments.map( x => x.treatment.name ).join(", ") }}</li>
              </ul>
              <hr class="dashed">
              <div class="text-sm text-medium color-gray-100 text-hidden">{{ order.customerName }} - {{ order.startDate | moment('dddd DD MMMM YYYY, HH:mm') }} WIB</div>
            </router-link>
          </swiper-slide>
        </swiper>

        <p class="text-center text-sm" v-if="saleses.length == 0">Tidak ada order</p>
        <div class="nav-slider">
          <div id="for-nav" class="d-flex justify-content-end align-items-center">
            <router-link to="/sales-order" class="text-sm text-medium">Lihat Semua</router-link>
            <div class="arrow-box" v-show="saleses.length > 0">
              <div id="waiting" class="swiper-button-next"></div>
              <div id="waiting" class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative mt-4">
        <div class="text-base color-gray-100 text-semiBold mb-3">Pekerjaan Akan Datang</div>
        <swiper class="swiper" :options="swiperOptionBooking">
          <swiper-slide v-for="order in bookings" :key="order.id">
            <router-link :to="'/booking-order/' + order.id" class="bg-white d-block rounded-12 p-3">
              <div class="d-flex align-items-center mb-3">
                <div class="bullet" :class="'bg-' + boStatusColor(order.status)"></div>
                <div class="text-semiBold text-hidden text-sm ms-3" :class="'color-' + boStatusColor(order.status)">{{ boStatusText(order.status )}}</div>
              </div>
              <ul class="item-info">
                <li>{{ order.noBooking }}</li>
                <li>{{ order.treatments.map( x => x.treatment.name ).join(", ") }}</li>
              </ul>
              <hr class="dashed">
              <div class="text-sm text-medium color-gray-100 text-hidden">{{ order.customerName }} - {{ order.startDate | moment('dddd DD MMMM YYYY, HH:mm') }} WIB</div>
            </router-link>
          </swiper-slide>
        </swiper>

        <p class="text-center text-sm" v-if="bookings.length == 0">Tidak ada order</p>
        <div class="nav-slider">
          <div id="for-nav" class="d-flex justify-content-end align-items-center">
            <router-link to="/booking-order" class="text-sm text-medium">Lihat Semua</router-link>
            <div class="arrow-box" v-show="bookings.length > 0">
              <div id="booking" class="swiper-button-next"></div>
              <div id="booking" class="swiper-button-prev"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overlay-popup ">
      <div id="staff" class="box-sm hidden bg-white rounded-12 p-3" v-if="promoDetail">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="text-semiBold">Detail Diskon</div>
          <button class="btn close-popup p-0" type="button"><span class="icon-ico-close"></span></button>
        </div>
        <div class="content-popup mb-4">
          <div class="text-sm text-medium mb-1">{{ promoDetail.name }}</div>
          <div class="text-sm color-red-100 mb-2">Diskon {{ promoDetail.type }}</div>
          <hr class="dashed">
          <!-- <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Target</div>
            <div class="text-sm color-gray-100 text-medium">Pelanggan Baru</div>
          </div> -->
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Kode Voucher</div>
            <div class="text-sm color-red-100 text-medium">{{ promoDetail.voucherCode}}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Min. Pembelian (Rp)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.minimumPayment | currency }}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" v-if="promoDetail.promoType == 1">
            <div class="text-sm color-gray-80">Nominal Diskon (Rp)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.discountPrice | currency }}</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2" v-else>
            <div class="text-sm color-gray-80">Persentase (%)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.discountPercentage | numFormat }}%</div>
          </div>
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="text-sm color-gray-80">Kuota (pengguna)</div>
            <div class="text-sm color-gray-100 text-medium">{{ promoDetail.quota | numFormat }}</div>
          </div>
        </div>
        <button class="btn btn-primary w-100" @click="copy(promoDetail.voucherCode)">Salin Kode Promo</button>
      </div>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      promos: [],
      saleses: [],
      bookings: [],
      
      swiperOptionPromo: {
        slidesPerView: 3,
        spaceBetween: 10,
        autoHeight: true,
        navigation: {
          nextEl: '#promo.swiper-button-next',
          prevEl: '#promo.swiper-button-prev'
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      },
      swiperOptionSales: {
        slidesPerView: 3,
        spaceBetween: 10,
        autoHeight: true,
        navigation: {
          nextEl: '#waiting.swiper-button-next',
          prevEl: '#waiting.swiper-button-prev'
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      },
      swiperOptionBooking: {
        slidesPerView: 3,
        spaceBetween: 10,
        autoHeight: true,
        navigation: {
          nextEl: '#booking.swiper-button-next',
          prevEl: '#booking.swiper-button-prev'
        },
        breakpoints: {
          375: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 24,
          },
        },
      },

      promoDetail: null,
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    getPromo() {
      let _ = this
      
      _.axios.get('/me/promotions'
          + '?page=1'
          + '&limit=5'
          + '&sortBy=id.desc'
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.promos.push({ ...elm })
            });
          } 
          // else {
          //   _.promos.push({
          //       "type": "Persentase",
          //       "id": 17,
          //       "kfsId": 8,
          //       "name": "Karen Larsen",
          //       "voucherCode": "Aliquam eligendi vol",
          //       "promoType": 2,
          //       "minimumPayment": 0,
          //       "discountPrice": null,
          //       "discountPercentage": 15,
          //       "discountPercentageMax": 15000,
          //       "startDate": "1983-11-15T22:24:00.000Z",
          //       "endDate": "2022-10-18T13:20:00.000Z",
          //       "quota": 0,
          //       "status": true,
          //       "allTargets": false,
          //       "createdAt": "2022-10-25T13:20:27.694Z",
          //       "updatedAt": "2022-10-25T13:22:07.696Z",
          //       "kfs": {
          //           "uniqueId": "FS008",
          //           "id": 8,
          //           "accountId": 11,
          //           "name": "KFS Dian",
          //           "address": "Jl. U.Suryadi No.15, Kertajaya, Kec. Padalarang, Kabupaten Bandung Barat, Jawa Barat 40553, Indonesia",
          //           "lat": "-6.849983831965629",
          //           "long": "107.49235124454957",
          //           "phone": "08991985040",
          //           "status": true,
          //           "level": 36,
          //           "since": "tag",
          //           "photo": null,
          //           "createdAt": "2022-10-18T08:47:17.868Z",
          //           "updatedAt": "2022-10-18T08:47:17.868Z"
          //       }
          //   })
          // }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getBooking() {
      let _ = this
      
      _.axios.get('/sales-order/booking-treatment'
          + '?page=1'
          + '&limit=5'
          + '&sortBy=startDate.asc'
          + '&kfsId=' + _.user.kfsId
          + '&status=-1' //Menunggu Waktu Layanan
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.bookings.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
    getSales() {
      let _ = this
      
      _.axios.get('/sales-order'
          + '?page=1'
          + '&limit=5'
          + '&sortBy=startDate.asc'
          + '&kfsId=' + _.user.kfsId
          + '&status=1' //Sedang Dilayani
        )
        .then( resp => {
          const data = resp.data.data
          if ( data.rows.length > 0 ) {
            data.rows.forEach(elm => {
              _.saleses.push({ ...elm })
            });
          }
        })
        .catch( err => {
          _.$notify({
            type: 'error',
            title: 'Terjadi Kesalahan ('+err.response.data.code+')',
            text: err.response.data.message
          });
        })
    },
  },
  mounted() {
    this.getPromo()
    this.getBooking()
    this.getSales()
  },
}
</script>