import { ValidationProvider, extend } from 'vee-validate';
import { 
  required, 
  max, 
  max_value,
  min_value,
  email,
  min,
  confirmed,
  regex
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} harus diisi'
});

extend('min', {
  ...min,
  params: ['length'],
  message: '{_field_} harus setidaknya {length} karakter'
});

extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_} maksimum {length} karakter'
});

extend('max_value', {
  ...max_value,
  message: '{_field_} seharusnya {max} atau kurang'
});

extend('min_value', {
  ...min_value,
  message: '{_field_} seharusnya {min} atau lebih'
});

extend('email', {
  ...email,
  message: '{_field_} harus berupa email'
});

extend('confirmed', {
  ...confirmed,
  message: 'Konfirmasi password baru tidak sama'
});

extend('regex', {
  ...regex,
  message: '{_field_} tidak sesuai'
});

extend('strong_password', {
  message: (fieldName, placeholders) => {
    return `${fieldName} harus berisi minimal 8 karakter, dan setidaknya berisi 1 huruf, 1 angka, dan 1 simbol`;
  },

  validate(value, args) {
    return (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/).test(value)
  },
});
export default ValidationProvider